import React, {
  useEffect, useMemo, useRef, useState,
} from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: 'black',
    margin: 0,
  },
  notchedOutlineTextInput: {
    border: 'none',
  },
  textField: {
    '&  .MuiFormHelperText-root': {
      backgroundColor: '#F8F8F8',
      margin: 0,
      paddingLeft: 10,
    },
    backgroundColor: 'white',
  },
}))

function formatDefaultValues(defaultValues, queryVars) {
  if (defaultValues) {
    const tmpCopy = { ...defaultValues }
    queryVars.forEach((key) => {
      if (tmpCopy[key] === undefined) {
        tmpCopy[key] = ''
      }
    })
    return tmpCopy
  }
  return undefined
}

export default function QueryDefaultValues({
  className, defaultValues: defaultValuesProp, delay, onUpdate, queryVars, size, validationErrors,
}) {
  const [defaultValues, setDefaultValues] = useState(
    formatDefaultValues(defaultValuesProp, queryVars),
  )
  const renderedFirstTime = useRef(false)
  const classes = useStyles()
  const onUpdateDebounced = useMemo(
    () => AwesomeDebouncePromise(onUpdate, delay || 1500),
    [onUpdate, delay],
  )
  const updateField = (event, fieldname) => {
    const newValues = {
      ...defaultValues,
      [fieldname]: event.target.value,
    }
    Object.keys(newValues)
      .filter((key) => !queryVars.includes(key))
      .forEach((key) => delete newValues[key])
    setDefaultValues(newValues)
  }

  useEffect(() => {
    if (renderedFirstTime.current) {
      onUpdateDebounced(defaultValues)
    }
    renderedFirstTime.current = true
  }, [defaultValues, renderedFirstTime])

  return (
    <Grid
      alignItems="center"
      className={className}
      container
      direction="row"
      justifyContent="flex-start"
      spacing={2}
    >
      {queryVars.map((queryVar) => (
        <Grid item key={`query-value-${queryVar}`} xs={size}>
          <p className={classes.inputLabel}>{queryVar}</p>
          <TextField
            className={classes.textField}
            error={validationErrors[queryVar]}
            fullWidth
            helperText={validationErrors[queryVar] || ' '}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutlineTextInput,
                root: classes.rootTextInput,
              },
            }}
            margin="dense"
            name={queryVar}
            onChange={(e) => updateField(e, queryVar)}
            type="text"
            value={defaultValues[queryVar]}
            variant="outlined"
          />
        </Grid>
      ))}
    </Grid>
  )
}
