import React from 'react'
import {
  Image, Link, StyleSheet, Text, View,
} from '@react-pdf/renderer'
import * as moment from 'moment'

import LinkIconPng from '../../../../assets/link.png'
import ModificationIconPng from '../../../../assets/modification.png'
import OfficialDiaryIconPng from '../../../../assets/official_diary.png'
import { titleCase } from '../../../utils'
import baseStyles from './styles'
import { resumeComponentsHelper, statusKindCountHelper } from './utils'

moment.locale('es')

const labelMapping = {
  MODIFICACIÓN: {
    icon: ModificationIconPng,
    label: 'Modificación',
  },
}

export default function getOfficialDiaryComponents(alertsData) {
  const rows = alertsData.filter((d) => d.query.key === 'pgen_diario_oficial')[0].result.results[0].data
    .map((d) => ({
      accion: d.row[0].accion,
      date: d.row[0].fecha_publicacion || '',
      icon: (labelMapping[d.row[0].accion] && labelMapping[d.row[0].accion].icon) || null,
      link_documento: d.row[0].link_documento,
      status: (labelMapping[d.row[0].accion] && labelMapping[d.row[0].accion].label)
        || titleCase(d.row[0].accion),
    }))
    .sort((r) => r.date)
    .reverse()

  const statusKindCount = statusKindCountHelper(rows)
  const resumeComponents = resumeComponentsHelper(baseStyles, 'Nº Cambios Diario Oficial', statusKindCount)

  return rows.length > 0 && ({
    main: (
      <View key="OfficialDiary">
        <View style={baseStyles.subSectionTitle}>
          <Image src={OfficialDiaryIconPng} style={baseStyles.sourceTitle.icon} />
          <Text>Diario Oficial</Text>
        </View>
        {rows.map((c) => (
          <View key={c.link_documento} style={baseStyles.sourceLayout}>
            <View style={baseStyles.sourceTitle}>
              <View style={styles.title}>
                <View style={styles.iconSpace}>
                  {c.icon && <Image src={c.icon} style={baseStyles.sourceTitle.icon} />}
                </View>
                <View>
                  <Text style={baseStyles.boldText}>
                    {c.status}
                  </Text>
                  <Text style={styles.date}>
                    {moment(c.date).format('DD/MM/YY')}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[baseStyles.sourceContent, styles.sourceContent]}>
              <Image src={LinkIconPng} style={baseStyles.sourceTitle.icon} />
              <Text style={[baseStyles.boldText, styles.link]}>
                <Link href={c.link_documento} src={c.link_documento}>Ir al expediente PDF</Link>
              </Text>
            </View>
          </View>
        ))}
      </View>
    ),
    resume: resumeComponents,
  })
}

const styles = StyleSheet.create({
  date: {
    fontSize: '10px',
  },
  iconSpace: {
    minWidth: '25px',
  },
  link: {},
  property: {
    width: '50%',
  },
  sourceContent: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '6px',
  },
})
