import React, { useContext, useState } from 'react'

import { initializeAzureProvider } from './utils'

const AzureContext = React.createContext()
export const useAzureContext = () => useContext(AzureContext)

export default function AzureProvider({ children }) {
  const [env, setEnv] = useState({})
  if (!env.authContext) {
    initializeAzureProvider(setEnv)
  }

  return (
    <AzureContext.Provider value={env}>
      {children}
    </AzureContext.Provider>
  )
}
