import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react'
import { FiDownload } from 'react-icons/fi'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PDFDownloadLink } from '@react-pdf/renderer'
import moment from 'moment'
import styled from 'styled-components'
import urlJoin from 'url-join'

import neo4jAPI from '../../../api/neo4jAPI'
import { getConfigParameters } from '../../../utils'
import { checkNodeType, getIcons, nodeProperties } from '../../utils'
import Alerts from './alerts'
import {
  CarProperties, CompanyProperties, PeopleProperties, RealStateProperties,
} from './node-properties'
import Reports from './reports'

import 'moment/locale/es'

moment.locale('es')

function NodePanel({ subqueries, ...props }, ref) {
  const [debug, setDebug] = useState()
  const [node, setNode] = useState()
  const [alerts, setAlerts] = useState()
  const [errors, setErrors] = useState(false)
  const subqueriesKeys = Object.keys(subqueries).filter((key) => subqueries[key].length)

  const queryPromise = (query) => new Promise(
    (resolve, reject) => { neo4jAPI.runQuery(query, true, resolve, reject) },
  )
  const haveQueries = (n) => subqueriesKeys.some((key) => n.types.includes(key))

  const onNodeChange = (n) => {
    const iconRule = getIcons(n)
    n._icon = iconRule ? iconRule.component : null
    setNode(n)
    setErrors(false)
    if (haveQueries(n)) {
      setAlerts()
      const queryPromises = Object.entries(subqueries)
        .filter(([k, v]) => n.types.includes(k) && v.length)
        .flatMap(([_k, v]) => v)
        .map((id) => queryPromise({
          id,
          neo4jQuery_default_values: {
            ...n.originalNode,
          },
        }))

      Promise.all(queryPromises)
        .then(setAlerts)
        .catch((e) => {
          setAlerts([])
          setErrors(e)
        })
    } else {
      setAlerts([])
    }
  }

  useImperativeHandle(ref, () => ({
    onNodeChange,
  }))

  useEffect(() => {
    if (debug === undefined) {
      getConfigParameters((params) => setDebug(params.debug) || false)
    }
  }, [debug])
  return node
    ? (
      <NodePanelWrapper>
        <Header>
          {node._icon && <Icon>{node._icon}</Icon>}
          {node.label}
        </Header>
        <GeneralInfo>
          <NodeFormatter node={node} />
        </GeneralInfo>
        {alerts === undefined && (
          <Spinner>
            <CircularProgress />
          </Spinner>
        )}
        {alerts && alerts.length > 0 && (
          <>
            <Alerts alerts={alerts} />
            <ReportSection alerts={alerts} node={node} readPDF={debug} {...props} />
          </>
        )}
        {errors && <CantRetriveNodeData />}
      </NodePanelWrapper>
    ) : <NoNode />
}
function isDateValid(date) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(Date.parse(date))
}
function nodeHasBirthDate(node) {
  // eslint-disable-next-line no-prototype-builtins
  return node.hasOwnProperty('fecha_nacimiento')
}

function nodeWasMinor(node) {
  return node.label === 'Persona Menor-de-Edad'
}

function UpdatePersonAge(node) {
  if (isDateValid(node.fecha_nacimiento) && nodeHasBirthDate(node)) {
    const milisecondsToYears = 1000 * 60 * 60 * 24 * 365
    node.edad = Math.floor((new Date() - new Date(node.fecha_nacimiento)) / milisecondsToYears)

    if (nodeWasMinor(node) && node.edad >= 18) {
      node.label = node.nombre ? node.nombre.toString() : 'Persona No-Identificada'
    }
  }
}

function NodeFormatter({ node }) {
  if (checkNodeType(node, 'Persona')) {
    UpdatePersonAge(node)
    return (
      node.label === 'Persona Menor-de-Edad'
        ? null
        : <PeopleProperties node={node} />
    )
  }
  if (checkNodeType(node, 'Empresa')) {
    return <CompanyProperties node={node} />
  }
  if (checkNodeType(node, 'BienRaiz')) {
    return <RealStateProperties node={node} />
  }
  if (checkNodeType(node, 'Auto')) {
    return <CarProperties node={node} />
  }

  return nodeProperties(node).map(({ name, value }) => (
    <Property key={name} name={name} value={value} />
  ))
}

function Property({ name, value }) {
  if (value instanceof Object) {
    return (
      <PropertyWrapper>
        <PropertyName>
          {name}
          {': '}
        </PropertyName>
        {value.coordinates ? `${value.coordinates[0]}, ${value.coordinates[1]}` : null}
      </PropertyWrapper>
    )
  }
  return (
    <PropertyWrapper>
      <PropertyName>
        {name}
        {': '}
      </PropertyName>
      {value}
    </PropertyWrapper>
  )
}

function NoNode() {
  return (
    <NodePanelWrapper>
      <Header>
        Nodos
      </Header>
      <GeneralInfo>
        <PropertyWrapper>
          Para mostrar información seleccione un nodo.
        </PropertyWrapper>
      </GeneralInfo>
    </NodePanelWrapper>
  )
}

function CantRetriveNodeData() {
  return (
    <NodePanelWrapper>
      <Header>
        Oops
      </Header>
      <GeneralInfo>
        <PropertyWrapper>
          Ha ocurrido un error durante la consulta.
        </PropertyWrapper>
      </GeneralInfo>
    </NodePanelWrapper>
  )
}

function ReportSection({
  alerts, history, match, node, readPDF = false,
}) {
  const renderPDF = (pdfAlerts) => {
    history.push({
      pathname: urlJoin(match.url, 'reporte'),
      state: {
        alerts: pdfAlerts,
        node: JSON.stringify(node),
      },
    })
  }

  const haveAlerts = alerts.filter(
    (alert) => alert.result.results.filter((result) => result.data.length).length,
  ).length > 0

  return haveAlerts && (
    <ReportSectionWrapper>
      <PDFDownloadLink
        document={(
          <Reports
            alerts={alerts}
            node={node}
          />
        )}
        fileName={`reporte_${node.rut || node.id}_${moment().format('DDMM_kmmss')}.pdf`}
      >
        {({ loading }) => (
          <DownloadButton
            disabled={loading}
            variant="outlined"
          >
            <FiDownload />
            {loading
              ? 'Generando informe...'
              : 'Descargar Informe'}
          </DownloadButton>
        )}
      </PDFDownloadLink>
      {readPDF && (
        <DownloadButton
          onClick={() => renderPDF(alerts)}
          variant="outlined"
        >
          Ver Informe
        </DownloadButton>
      )}
    </ReportSectionWrapper>
  )
}

export default forwardRef(NodePanel)

const NodePanelWrapper = styled.div`
  background-color: white;
  text-align: left;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 323px;
  width: 323px;
`
const Header = styled.div`
  display: grid;
  grid: 1fr / auto 1fr;
  gap: 10px;
  align-items: center;
  background: #ECEDF3;
  color: #404245;
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
`

const GeneralInfo = styled.div`
  padding: 12px;
  padding-left: 46px;
  > * + *{
    margin-top: 6px;
  }
`

const PropertyWrapper = styled.div`
  font-size: 12px;
  word-wrap: break-word;
`

const PropertyName = styled.span`
  font-weight: 700;
`

const Icon = styled.div`
  max-width: 20px;
  > * {
    margin-right: 5px;
    width: 22px;
    align-items: center;
    display: flex;
    width: 18px;
  }
`

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;
`

const DownloadButton = styled(Button)`
  font-size: 9px;
  font-weight: 700;
  text-transform: none;
  margin: 0 auto;
  svg {
    margin-right: 5px;
  }
`

const ReportSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #E0E0E0;
  padding: 12px 0;
`
