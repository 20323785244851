import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import PlayIcon from '@material-ui/icons/PlayCircleFilledWhite'
import urlJoin from 'url-join'

import neo4jAPI from '../../api/neo4jAPI'
import UIStrings from '../../strings'
import ErrorView from '../error-view'
import GenericTable from '../generic-table'
import { ESCSearchInputWorkaround, getMustacheVariables, processNeo4jTable } from '../utils'
import QueryDefaultValues from './query-default-values'

const useStyles = makeStyles(() => ({
  defaultValuesContainer: {
    paddingTop: '1rem',
  },
  pageContainer: {
    marginTop: '1rem',
  },
  playIcon: {
    '&:hover': {
      color: '#71B34C',
    },
    color: '#80868B',
  },
  tableContainer: {
    overflow: 'auto',
    textAlign: 'center',
  },
}))

function getQuery({ location, query = null }) {
  return location.state && location.state.query
    ? location.state.query : query
}

function TableOrError({
  errorMessage, onRefresh, query, table,
}) {
  return table.data && table.data.length > 0
    ? (
      <GenericTable
        columns={table.columns}
        data={table.data}
        name={query.name}
        onRefresh={onRefresh}
      />
    ) : <ErrorView errorMessage={errorMessage} />
}

export default function QueryTable({
  breadcrumbs: breadcrumbsProps, history, location, onUpdate, query: queryProps,
}) {
  const [errorMessage, setErrorMessage] = useState([UIStrings.table.notRows])
  const [refresh, setRefresh] = useState(false)
  const [table, setTable] = useState()
  const [query, setQuery] = useState(getQuery({ location, query: queryProps }))
  const classes = useStyles()
  const ref = React.createRef()

  const showGraph = (indexes, row) => {
    const ruts = indexes.map((index) => row[index])
    const breadcrumbs = Array.from(
      breadcrumbsProps
        || (location.state && location.state.breadcrumbs
          ? location.state.breadcrumbs : []),
    )
    breadcrumbs[breadcrumbs.length - 1] = { label: query.name, route: location.pathname }

    history.push({
      pathname: urlJoin('/consultas', 'busqueda'),
      state: {
        breadcrumbs,
        neo4jProps: {
          ruts: JSON.stringify(ruts),
        },
      },
    })
  }

  if (query && !table) {
    neo4jAPI.runQuery(query, refresh, (res) => {
      const neo4jTable = processNeo4jTable(res.result)
      const rutIndexes = neo4jTable.columns.map(
        (column, index) => ((column && column.label && column.label.startsWith('Rut ')) ? index : null),
      ).filter((index) => index !== null)
      if (rutIndexes.length > 0) {
        neo4jTable.columns.push({
          name: 'Ver',
          options: {
            customBodyRender: (_value, tableMeta) => (
              <IconButton
                className={classes.playIcon}
                onClick={() => showGraph(rutIndexes, tableMeta.rowData)}
              >
                <PlayIcon />
              </IconButton>
            ),
          },
          sort: false,
        })
      }
      setTable(neo4jTable)
      setRefresh(false)
      onUpdate(res.executed_at)
    }, (errors) => {
      setTable({ data: [] })
      setErrorMessage(errors && Array.isArray(errors)
        ? errors.join('<br>')
        : UIStrings.server.error)
      setRefresh(false)
      onUpdate(null)
    })
  }

  const updateQueryField = (fieldname, value) => {
    const newQuery = {
      ...query,
      [fieldname]: value,
    }
    setQuery(newQuery)
    setTable(null)
  }

  const refreshData = () => {
    setRefresh(true)
    setTable(null)
  }

  const queryVars = query && query.neo4jQuery ? getMustacheVariables(query.neo4jQuery) : []

  return (
    <Grid
      alignItems="stretch"
      container
      direction="row"
      justify="flex-start"
      spacing={2}
    >
      { queryVars.length > 0 && (
        <Grid item xs={12}>
          <QueryDefaultValues
            className={classes.defaultValuesContainer}
            defaultValues={query.neo4jQuery_default_values}
            onUpdate={(values) => updateQueryField('neo4jQuery_default_values', values)}
            queryVars={queryVars}
            size={3}
          />
        </Grid>
      )}
      <Grid
        className={classes.tableContainer}
        item
        onKeyDown={ESCSearchInputWorkaround(ref)}
        ref={ref}
        xs={12}
      >
        {!table
          ? <CircularProgress />
          : (
            <TableOrError
              errorMessage={errorMessage}
              onRefresh={refreshData}
              query={query}
              table={table}
            />
          )}
      </Grid>
    </Grid>
  )
}
