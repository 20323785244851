export default {
  graph: {
    notNodes: 'No hay nodos para mostrar. Intente con otros parámetros.',
  },
  server: {
    error:
    // TODO Receive messages from backend. The component it's made ti receive a list like this.
            [
              'Para consultar por un <b>RUT</b> debe hacerlo <b>sin puntos, guión ni número verificador</b>. (Ej: 12345678)',
              'Para consultar por una <b>Causa</b> debe ingresar la <b>primera letra en mayúscula y separar los números con un guión</b>. (Ej: A-1234-5678)',
              'Los campos como Distancia o Participación <b>sólo aceptan números</b>, no ingrese ni símbolos ni letras.',
              'Los campos como Nombre o Apellido <b>sólo aceptan letras</b>, no ingrese ni símbolos ni números.',
            ],
  },
  table: {
    notRows: 'No se encuentran valores para mostrar',
  },
}
