import AccordionBase from '@material-ui/core/Accordion'
import AccordionSummaryBase from '@material-ui/core/AccordionSummary'
import styled from 'styled-components'

export const Accordion = styled(AccordionBase)`
  box-shadow: none;

  &:before {
    background: none;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }

  &.Mui-expanded:last-child {
    margin-bottom: 16px !important;
  }
`

export const AccordionSummary = styled(AccordionSummaryBase)`
  color: #404245;
  font-size: 14px;
  font-weight: 700;
`
