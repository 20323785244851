import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

import Reports from '../components/query/graph/reports'
import Query from '../components/query/query'
import QueryCards from '../components/query/query-cards'
import QueryList from '../components/query/query-list'
import QueryView from '../components/query/query-view'
import { spaces } from '../constants'

const useStyles = makeStyles(() => ({
  container: {
    padding: spaces.l,
  },
}))

export default function QueryPage({ location, match }) {
  const classes = useStyles()
  const breadcrumbs = location.state && location.state.breadcrumbs
    ? location.state.breadcrumbs : [{
      label: 'Catálogo de Consultas',
      route: '/consultas',
    }]

  return (
    <Container className={classes.container} maxWidth="lg">
      <Switch>
        <Route exact path={`${match.url}/agregar`} render={(params) => <Query {...params} breadcrumbs={breadcrumbs} />} />
        <Route exact path={`${match.url}/:id/editar`} render={(params) => <Query {...params} breadcrumbs={breadcrumbs} />} />
        <Route exact path={`${match.url}/:id`} render={(params) => <QueryView {...params} breadcrumbs={breadcrumbs} />} />
        <Route exact path={`${match.url}/:id/reporte`} render={(params) => <Reports {...params} />} />
        <Route render={(params) => <QueryCards {...params} breadcrumbs={breadcrumbs} />} />
        {' '}
        {/* TODO: how to select between Query and Card lists */}
        <Route render={(params) => <QueryList {...params} breadcrumbs={breadcrumbs} />} />
      </Switch>
    </Container>
  )
}
