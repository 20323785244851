import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Login from '../components/login'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '8rem',
  },
}))

export default function LoginPage(props) {
  const classes = useStyles()
  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      direction="row"
      justify="center"
    >
      <Grid item lg={3} md={4} xl={3} xs={6}>
        <Login {...props} />
      </Grid>
    </Grid>
  )
}
