import React from 'react'
import styled from 'styled-components'

import { ReactComponent as CircleBase } from '../../../../assets/legend_circle.svg'
import Judiciary from './judiciary'
import OfficialDiary from './official-diary'

const alertComponents = [
  {
    component: Judiciary,
    key: 'pgen_causas',
  },
  {
    component: OfficialDiary,
    key: 'pgen_diario_oficial',
  },
]

const getAlertByKey = (alerts, key) => alerts.find((alert) => alert.query.key === key)
const alertHasData = (alert) => alert.result.results[0].data.length

export default function Alerts({ alerts = [] }) {
  const alertKeys = alerts.map((d) => d.query.key)
  const selectedAlertComponents = alertComponents
    .filter(
      (alert) => alertKeys.includes(alert.key) && alertHasData(getAlertByKey(alerts, alert.key)),
    )
    .map((alert) => <alert.component data={getAlertByKey(alerts, alert.key)} key={alert.key} />)

  return selectedAlertComponents.length > 0 && (
    <AlertsWrapper>
      <Header>
        <AlertCircle bordercolor="#E6745C" fillcolor="#E6745C" />
        Alertas
      </Header>
      {selectedAlertComponents}
    </AlertsWrapper>
  )
}

const Header = styled.div`
  display: grid;
  grid: 1fr / min-content 1fr;
  gap: 10px;
  align-items: center;
  background: #ECEDF3;
  color: #404245;
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 6px;
`

const AlertCircle = styled(CircleBase)`
  fill: ${(props) => props.fillcolor};
  circle {
      stroke: ${(props) => props.bordercolor};
  }
`

const AlertsWrapper = styled.div``
