import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import PlayIcon from '@material-ui/icons/PlayCircleFilledWhite'
import * as moment from 'moment'
import MUIDataTable from 'mui-datatables'
import urlJoin from 'url-join'

import queryAPI from '../../api/queryAPI'
import BreadCrumbs from '../breadcrumbs'
import { defaultMuiTableTheme, defaultTableOptions, ESCSearchInputWorkaround } from '../utils'

import 'moment/locale/es'

moment.locale('es')

const useStyles = makeStyles(() => ({
  addIcon: {
    '&:hover': {
      color: '#048CC2',
    },
    color: '#80868B',
  },
  deleteIcon: {
    '&:hover': {
      color: '#DE4242',
    },
    color: '#80868B',
  },
  editIcon: {
    '&:hover': {
      color: '#FFA600',
    },
    color: '#80868B',
  },
  gridContainer: {
    marginTop: '1rem',
  },
  playIcon: {
    '&:hover': {
      color: '#71B34C',
    },
    color: '#80868B',
  },
}))

export default function QueryList({ breadcrumbs, history, match }) {
  const [queries, setQueries] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()
  const tableRef = React.createRef()
  const createQueryAllowed = JSON.parse(localStorage.getItem('can_create_query'))

  if (queries.length === 0) {
    queryAPI.getAll((res) => {
      if (res.results.length !== 0) {
        setQueries(res.results.map((query) => {
          query.creator_name = `${query.creator_data.first_name} ${query.creator_data.last_name}`
          return query
        }))
      }
      setIsLoading(false)
    })
  }

  const runQuery = (query) => () => {
    history.push({
      pathname: urlJoin(match.url, query.id.toString()),
      state: {
        query,
      },
    })
  }

  const addQuery = () => history.push({
    pathname: urlJoin(match.url, 'agregar'),
  })

  const editQuery = (query) => () => history.push({
    pathname: urlJoin(match.url, query.id.toString(), 'editar'),
    state: {
      query,
    },
  })

  const getQueryFomTableMeta = (tableMeta) => {
    let index = null
    if (tableMeta.tableData.length > 0) {
      const value = tableMeta.tableData.find(
        (row) => row.data.toString() === tableMeta.rowData.toString(),
      )
      index = value ? value.index : index
    } else {
      index = tableMeta.rowIndex
    }
    return index === null ? index : queries[index]
  }

  const columns = [
    { label: 'Consulta', name: 'name' },
    { label: 'Creador/a', name: 'creator_name' },
    { label: 'Descripción', name: 'description' },
    { label: 'Versión', name: 'version' },
    {
      label: 'Último autor',
      name: 'versions.0.modifier',
      options: {
        customBodyRender: (value) => `${value.first_name} ${value.last_name}`,
      },
    },
    {
      label: 'Fecha de última modificación',
      name: 'versions.0.created_at',
      options: {
        customBodyRender: (value) => moment(value).format('DD-MM-YY H:mm:ss'),
      },
    },
    {
      name: 'Editar',
      options: {
        customBodyRender: (_value, tableMeta) => (
          <IconButton
            className={classes.editIcon}
            onClick={editQuery(getQueryFomTableMeta(tableMeta))}
          >
            <EditIcon />
          </IconButton>
        ),
        sort: false,
      },
    },
    {
      name: 'Ver',
      options: {
        customBodyRender: (_value, tableMeta) => (
          <IconButton
            className={classes.playIcon}
            onClick={runQuery(getQueryFomTableMeta(tableMeta))}
          >
            <PlayIcon />
          </IconButton>
        ),
        sort: false,
      },
    },
  ]

  const getMuiTheme = () => createTheme({
    overrides: {
      ...defaultMuiTableTheme.overrides,
      MUIDataTableBodyRow: {
        ...defaultMuiTableTheme.overrides.MUIDataTableBodyRow,
        root: {
          ...defaultMuiTableTheme.overrides.MUIDataTableBodyRow.root,
          '&:nth-child(n)': {
            '& > td:nth-child(2n + 8)': {
              textAlign: 'center',
            },
          },
        },
      },
    },
  })

  const options = {
    ...defaultTableOptions,
    customToolbar: () => (
      createQueryAllowed && (
        <Tooltip placement="top" title="Agregar Consulta">
          <IconButton className={classes.addIcon} onClick={addQuery}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )
    ),
    pagination: queries.length > 5,
    rowsPerPage: 20,
    rowsPerPageOptions: [20, 50, 100],
    textLabels: {
      ...defaultTableOptions.textLabels,
      body: {
        ...defaultTableOptions.textLabels.body,
        noMatch: isLoading ? <CircularProgress /> : 'No se encuentran consultas cargadas',
      },
    },
  }

  return (
    <Grid
      alignItems="stretch"
      container
      direction="column"
      justify="center"
    >
      <Grid item>
        <BreadCrumbs crumbs={breadcrumbs} />
      </Grid>
      <Grid
        item
        onKeyDown={ESCSearchInputWorkaround(tableRef)}
      >
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            columns={columns}
            data={queries}
            options={options}
            ref={tableRef}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  )
}
