import React from 'react'
import {
  Image, StyleSheet, Text, View,
} from '@react-pdf/renderer'

import ApplicantIconPng from '../../../../assets/applicant.png'
import DefendantIconPng from '../../../../assets/defendant.png'
import JudiciaryIconPng from '../../../../assets/judiciary.png'
import { nodeProperties } from '../../../utils'
import baseStyles from './styles'
import { resumeComponentsHelper, statusKindCountHelper } from './utils'

const labelMapping = {
  'DDO.': {
    icon: DefendantIconPng,
    label: 'Demandado',
  },
  'DTE.': {
    icon: ApplicantIconPng,
    label: 'Demandante',
  },
}

export default function getJudiciaryComponents(alertsData) {
  const rows = alertsData.filter((d) => d.query.key === 'pgen_causas')[0].result.results[0].data
    .map((d) => ({
      causa: d.row[2],
      icon: (labelMapping[d.row[0]] && labelMapping[d.row[0]].icon) || null,
      role: d.row[1],
      status: (labelMapping[d.row[0]] && labelMapping[d.row[0]].label) || d.row[0],
    }))
    .filter((r) => r.status && r.role && r.causa)

  const statusKindCount = statusKindCountHelper(rows)
  const resumeComponents = resumeComponentsHelper(baseStyles, 'Nº Causas Vigentes', statusKindCount)

  return rows.length > 0 && ({
    main: (
      <View key="Judiciary">
        <View style={baseStyles.subSectionTitle}>
          <Image src={JudiciaryIconPng} style={baseStyles.sourceTitle.icon} />
          <Text>Poder Judicial</Text>
        </View>
        {rows.map((c) => (
          <View key={c.role} style={baseStyles.sourceLayout}>
            <View style={baseStyles.sourceTitle}>
              <View style={styles.title}>
                <Image src={c.icon} style={baseStyles.sourceTitle.icon} />
                <Text style={baseStyles.boldText}>
                  {c.status}
                </Text>
              </View>
            </View>
            <View style={[baseStyles.sourceContent, baseStyles.nodeProperties]}>
              {nodeProperties(c.causa).map((p) => (
                <View key={p.name} style={[baseStyles.property, styles.property]}>
                  <Text style={baseStyles.boldText}>{p.name}</Text>
                  <Text>{p.value}</Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    ),
    resume: resumeComponents,
  })
}

const styles = StyleSheet.create({
  property: {
    width: '50%',
  },
  title: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '6px',
  },
})
