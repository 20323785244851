import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

import App from './App'
import AzureProvider from './AzureProvider'
import { colors } from './constants'
import * as serviceWorker from './serviceWorker'
import UnauthApp from './unauth-app'
import { getConfigParameters as initializeSentry, initializeGA, isUserAuthenticated } from './utils'

import './index.css'

const THEME = createTheme({
  palette: {
    primary: {
      main: colors.searchBarGrey,
    },
    secondary: {
      main: colors.searchBarGrey,
    },
    text: {
      primary: colors.principalFont,
    },
  },
  typography: {
    fontFamily: 'HankenGroteskRegular',
  },
})

initializeGA(() => initializeSentry(
  // eslint-disable-next-line react/no-render-return-value
  () => ReactDOM.render(
    <MuiThemeProvider theme={THEME}>
      <AzureProvider>
        <BrowserRouter>
          <Route
            path="/"
            render={(props) => (
              isUserAuthenticated()
                ? <App {...props} />
                : <UnauthApp {...props} />
            )}
          />
        </BrowserRouter>
      </AzureProvider>
    </MuiThemeProvider>,
    document.getElementById('root'),
  ),
))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
