import React, { useState } from 'react'
import CardBase from '@material-ui/core/Card'
import CardContentBase from '@material-ui/core/CardContent'
import CircularProgressBase from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Search from '@material-ui/icons/Search'
import * as moment from 'moment'
import styled from 'styled-components'
import urlJoin from 'url-join'

import queryAPI from '../../api/queryAPI'
import {
  ImgAllMalla,
  ImgAscendants,
  ImgAscendantsDescendants,
  ImgBienesRaices,
  ImgByRut,
  ImgCase,
  ImgCausasFactoring,
  ImgDescendants,
  ImgFamilyRut,
  ImgHolding,
  ImgNombreMalla,
  ImgPadronVehicular,
  ImgRutRelationship,
} from '../../assets/queryIcons'
import { colors, spaces } from '../../constants'
import BreadCrumbs from '../breadcrumbs'

import 'moment/locale/es'

moment.locale('es')

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  searchBar: {
    background: 'white',
  },
}))

const iconMapping = {
  'all-malla': ImgAllMalla,
  'busqueda-por-rol-de-causa': ImgCase,
  'causas-asociadas-a-rut': ImgCase,
  'causas-con-empresas-factoring-bancos': ImgCausasFactoring,
  'consulta-por-rut': ImgByRut,
  'empresas-ascendientes': ImgAscendants,
  'empresas-ascendientes-y-descendientes': ImgAscendantsDescendants,
  'empresas-descendientes': ImgDescendants,
  'familia-por-nombre': ImgFamilyRut,
  'familia-por-rut': ImgFamilyRut,
  holdings: ImgHolding,
  'malla-padron': ImgPadronVehicular,
  'malla-raices': ImgBienesRaices,
  'nombre-malla': ImgNombreMalla,
  'relaciones-entre-dos-ruts': ImgRutRelationship,
}

export default function QueryList({ breadcrumbs, history, match }) {
  const [queries, setQueries] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [filteredQueries, setFilteredQueries] = useState([])
  const classes = useStyles()

  if (queries.length === 0) {
    queryAPI.getAll((res) => {
      if (res.results.length !== 0) {
        const resQueries = res.results.map((query) => {
          query.creator_name = `${query.creator_data.first_name} ${query.creator_data.last_name}`
          query.icon = iconMapping[query.key]
          return query
        })
        setQueries(resQueries)
        setFilteredQueries(resQueries)
      }
      setIsLoading(false)
    })
  }

  const filterQueries = (e) => setFilteredQueries(
    queries.filter((query) => query.name.toLowerCase().includes(e.target.value.toLowerCase())),
  )

  const runQuery = (query) => {
    delete query.icon
    return (() => history.push({
      pathname: urlJoin(match.url, query.id.toString()),
      state: { query },
    }))
  }

  return (
    <Grid
      container
      direction="column"
      spacing={4}
    >
      <Grid item>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
        >
          <BreadCrumbs crumbs={breadcrumbs} />
          <TextField
            className={classes.searchBar}
            id="outlined-basic"
            InputProps={{
              classes: { notchedOutline: classes.noBorder },
              onChange: filterQueries,
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: colors.lightGrey }} />
                </InputAdornment>
              ),
            }}
            placeholder="Ingrese consulta"
            size="small"
            variant="outlined"
          />
        </Grid>
      </Grid>
      {isLoading ? <CircularProgress style={{ color: 'black' }} /> : (
        <Grid item>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={3}
          >
            {filteredQueries.map((query) => (
              <Grid item key={query.id}>
                <QueryCard
                  description={query.description}
                  icon={query.icon}
                  onClick={runQuery(query)}
                  title={query.name}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

function QueryCard({
  description, icon: Icon, onClick, title,
}) {
  return (
    <Card onClick={onClick}>
      <CardContent>
        <QueryIcon>
          {Icon && <Icon />}
        </QueryIcon>
        <QueryTitle>
          {title}
          <Tooltip aria-label="Info" title={description}>
            <InfoIcon style={{ fontSize: 16 }} />
          </Tooltip>
        </QueryTitle>
      </CardContent>
    </Card>
  )
}

const CardContent = styled(CardContentBase)`
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
`

const QueryIcon = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
`

const QueryTitle = styled.div`
    display: grid;
    grid: 1fr / 8fr 1fr;
    align-items: flex-end;
    font-weight: bold;
    gap: ${spaces.s};
    font-size: 18px;
`

const Card = styled(CardBase)`
    height: 210px;
    width: 227px;
    cursor: pointer;
`

const InfoIcon = styled(InfoOutlined)`
    font-size: 12px;
    margin: ${spaces.xs} 0;
`

const CircularProgress = styled(CircularProgressBase)`
    margin: 0 auto;
`
