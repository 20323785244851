import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import * as moment from 'moment'

import queryAPI from '../../api/queryAPI'
import { searchQuery } from '../../constants'
import BreadCrumbs from '../breadcrumbs'
import GraphPage from './graph/graph'
import TablePage from './table'

import 'moment/locale/es'

moment.locale('es')

const useStyles = makeStyles(() => ({
  lastUpdateText: {
    color: '#6F8294',
    fontSize: '14px',
    textAlign: 'right',
  },
}))

const TableOrGraph = ({
  breadcrumbs, onUpdate, query, ...props
}) => (query.type === 'T'
  ? (
    <TablePage
      breadcrumbs={breadcrumbs}
      onUpdate={onUpdate}
      query={query}
      {...props}
    />
  ) : <GraphPage onUpdate={onUpdate} query={query} {...props} />
)

export default function QueryView({
  breadcrumbs: breadcrumbsProps, history, location, match, query: queryProps, ...props
}) {
  const classes = useStyles()
  const [updatedAt, setUpdatedAt] = useState()
  const [query, setQuery] = useState(
    queryProps || (
      location.state && location.state.query ? location.state.query : null
    ),
  )

  const breadcrumbs = Array.from(breadcrumbsProps) || []
  if (!query || match.params.id.toString() !== query.id.toString()) {
    const id = match.params.id || query.id
    if (Number.isInteger(id) || Number.isInteger(parseInt(id, 10))) {
      queryAPI.get(
        id,
        (getQuery) => setQuery(getQuery),
        () => history.push('/consultas'),
      )
    } else {
      setQuery(searchQuery)
    }
  } else {
    breadcrumbs.push({
      label: query.name,
    })
  }

  return (
    <Grid
      container
      direction="row"
    >
      <Grid item xs={8}>
        <BreadCrumbs crumbs={breadcrumbs} />
      </Grid>
      {updatedAt && (
        <Grid className={classes.lastUpdateText} item xs={4}>
          Actualizado:
          {' '}
          {moment(updatedAt).format('DD/MM/YY - H:mm')}
        </Grid>
      )}
      <Grid item xs={12}>
        {Boolean(query && query.type) && (
          <TableOrGraph
            breadcrumbs={breadcrumbs}
            history={history}
            location={location}
            match={match}
            onUpdate={setUpdatedAt}
            query={query}
            {...props}
          />
        )}
      </Grid>
    </Grid>
  )
}
