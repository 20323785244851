import React from 'react'

import BusinessIconPng from './assets/business.png'
import { ReactComponent as BusinessIcon } from './assets/business.svg'
import PersonIconPng from './assets/person.png'
import { ReactComponent as PersonIcon } from './assets/person.svg'

export const graphAttributes = {
  actividad: ['cod_act_econ', 'act_econ', 'cod_acteco', 'desc_acteco'],
  contribuyente: [
    'rut',
    'dv',
    'razon_social',
    'tramo_ventas',
    'venta_min_UF',
    'venta_max_UF',
    'n_socios',
    'n_sociedades',
    'act_econ',
    'act_econ_ppal',
    'comuna',
  ],
  grupo: ['nombre', 'venta_min_UF', 'n_miembros', 'rut_controlador', 'tipo_grupo'],
}

export const graphKeywords = ['id', 'label', 'types']
export const graphBlacklistKeywords = ['_label']
export const graphHiddenProperties = graphKeywords.concat(graphBlacklistKeywords).concat(['classNames', 'originalNode', '_icon', 'aparece_en_diario_oficial', 'documento'])

export const searchQuery = {
  description: null,
  id: 'busqueda',
  name: 'Búsqueda por RUTs',
  neo4jQuery: `
    match (c0:Contribuyente), (c1:Contribuyente)
    where c0.rut < c1.rut
      and c0.rut in {{{ruts}}}
      and c1.rut in {{{ruts}}}
    with c0, c1
    match (c0:Contribuyente)-[r00:CONTROLADO_POR]->(g0:Grupo)-[r01:CONTROLADO_POR]-(d0:Contribuyente),
          (c1:Contribuyente)-[r10:CONTROLADO_POR]->(g1:Grupo)-[r11:CONTROLADO_POR]-(d1:Contribuyente)
    optional match pF=(c0)-[:SOCIO_DE*1..10]->(c1)
    optional match pB=(c0)<-[:SOCIO_DE*1..10]-(c1)
    optional match pA=(c0)<-[:ACTIVIDAD_DE]-(a:Actividad)-[:ACTIVIDAD_DE]->(c1)
    return c0, r00, d0, r01, g0, pF, pB, pA, c1, r10, d1, r11, g1
    `,
  neo4jQuery_default_values: { ruts: [] },
  type: 'G',
}

export const iconRules = [
  {
    component: <BusinessIcon key="Empresa" />,
    key: 'Empresa',
    png: BusinessIconPng,
  },
  {
    component: <PersonIcon key="Persona" />,
    key: 'Persona',
    png: PersonIconPng,
  },
]

export const colors = {
  backgroundGrey: '#F8F8F8',
  lightGrey: '#BDBDBD', // TODO: use it in graphlab/front/src/index.css
  linkBlue: '#3892F7',
  principalFont: '#404245',
  searchBarGrey: '#ECEDF3',
}

export const spaces = {
  l: '24px',
  m: '12px',
  s: '6px',
  xl: '36px',
  xs: '3px',
  xxl: '48px',
}

export const searchLabelsForInputMappedToRut = {
  nombre: 'Nombre',
  nombreRut: 'Nombre o Rut',
  rut: 'RUT',
}

export const searchLabelsForName = {
  materno: 'Apellido materno',
  nombre: 'Nombre(s)',
  paterno: 'Apellido paterno',
}

export const mainTypeValues = ['Empresa', 'PersonaNatural', 'Persona']
