import React from 'react'
import {
  Text, View,
} from '@react-pdf/renderer'

export const statusKindCountHelper = (rows) => rows.reduce((acc, cur) => {
  if (acc[cur.status]) {
    acc[cur.status] += 1
  } else {
    acc[cur.status] = 1
  }
  return acc
}, {})

export const resumeComponentsHelper = (
  baseStyles,
  text,
  statusKindCountReduced,
) => Object.entries(statusKindCountReduced)
  .map(([k, v]) => (
    <View key={k} style={[baseStyles.property]}>
      <Text style={baseStyles.boldText}>{text}</Text>
      <Text style={baseStyles.boldText}>{k}</Text>
      <Text>{v}</Text>
    </View>
  ))
