import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/Lock'
import PersonIcon from '@material-ui/icons/Person'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import AuthAPI from '../api/authAPI'
// Images
import { ReactComponent as MicrosoftIcon } from '../assets/microsoftIcon.svg'
import { useAzureContext } from '../AzureProvider'

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
  },
  errorText: {
    color: 'red',
    marginBottom: 0,
    marginTop: '0.7rem',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    width: '100%', // Fix IE 11 issue.

  },
  icons: {
    color: '#80868B',
    height: '0.85em',
    width: '0.85em',
  },
  microsoftLogin: {
    backgroundColor: '#FFFFFF',
    border: '0.25px solid #B8B8B8',
    borderRadius: '2px',
    boxShadow: 'none',
    color: '#666666',
    fontSize: '14px',
    margin: '15px auto 12px auto',
    textTransform: 'none',
    width: '80%',
  },
  paper: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '350px',
    padding: '2rem 3rem',
  },
  submit: {
    backgroundColor: '#333333',
    borderRadius: '2px',
    boxShadow: 'none',
    color: 'white',
    fontSize: '14px',
    margin: '24px auto 0px auto',
    textTransform: 'none',
    width: '80%',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 300,
    maxWidth: '170px',
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    paddingBottom: '0.5rem',
  },
}))

const getAzureToken = () => {
  const url = window.location
  const urlParams = url.hash.substring(1)
  return new URLSearchParams(urlParams).get('id_token')
}

const getRedirectParam = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get('force_microsoft')
}

export default function Login({ history }) {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { adalConfig, authContext } = useAzureContext()
  const azureToken = getAzureToken()
  const forceRedirectAzureLogin = getRedirectParam()

  const azureLogin = () => {
    setIsLoading(true)
    authContext.login()
  }

  const loginCallback = (res) => {
    setIsLoading(false)
    if (res.ok) {
      AuthAPI.getUserData(() => {
        const redirect = localStorage.getItem('redirect')
        if (redirect) {
          localStorage.removeItem('redirect')
          history.push(redirect)
        }
        if (history.location.pathname === '/' && !redirect) {
          history.push('/')
        }
      })
    } else {
      setError(true)
    }
  }
  const azureLoginErrorCallback = (res) => {
    if (res.status !== 200) {
      setOpenDialog(true)
    }
  }

  if (forceRedirectAzureLogin && authContext) {
    azureLogin()
  }
  if (azureToken && !isLoading) {
    setIsLoading(true)
  }
  if (azureToken && AuthAPI.apiURL) {
    AuthAPI.loginAzure(azureToken, loginCallback, azureLoginErrorCallback)
  }

  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault()
    const data = new FormData(event.target)
    const user = data.getAll('username')[0]
    const pass = data.getAll('password')[0]
    AuthAPI.login(user, pass, loginCallback)
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const handleMouseDownPassword = (event) => event.preventDefault()

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setIsLoading(false)
    localStorage.clear()
    sessionStorage.clear()
    history.push('/')
  }

  const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' }

  return isLoading
    ? (
      <>
        <div>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleCloseDialog}
            open={openDialog}
          >
            <DialogTitle id="alert-dialog-title">
              Ha ocurrido un error ... :(
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Usuario no agregado al sistema, informe al administrador
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cerrar</Button>
            </DialogActions>
          </Dialog>
        </div>

        <CircularProgress style={{ display: 'block', margin: 'auto' }} />
      </>
    )
    : (
      <div className={classes.paper}>
        <Typography className={classes.title} component="h1" variant="h5">
          Bienvenido
        </Typography>
        <Typography className={classes.subtitle} component="h6" variant="h6">
          Ingrese sus credenciales para acceder
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            error={error}
            fullWidth
            id="username"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              placeholder: 'Nombre de Usuario',
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon className={classes.icons} />
                </InputAdornment>
              ),
              style: inputStyle,
            }}
            margin="normal"
            name="username"
            required
            size="small"
            variant="outlined"
          />
          <TextField
            error={error}
            fullWidth
            id="password"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              placeholder: 'Contraseña',
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon className={classes.icons} />
                </InputAdornment>
              ),
              style: inputStyle,
            }}
            margin="normal"
            name="password"
            required
            size="small"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
          />
          {error && <p className={classes.errorText}>Usuario o contraseña inválida</p>}
          <Button
            className={classes.submit}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
          >
            Iniciar Sesión
          </Button>
          {adalConfig && (
          <Button
            className={classes.microsoftLogin}
            color="primary"
            onClick={azureLogin}
            size="small"
            variant="contained"
          >
            <MicrosoftIcon style={{ marginRight: '10px' }} />
            Continuar con Microsoft
          </Button>
          )}
        </form>
      </div>
    )
}
