import * as Sentry from '@sentry/react'

export default function setSentry(config) {
  Sentry.init({
    dsn: config.sentryDsn || '',
    environment: config.sentryEnv || 'develop',
    release: config.sentryRelease || '',
    tracesSampleRate: config.sentryTracesSampleRate || 0.25,
  })
  Sentry.setTag('service', `${config.servicePrefix || 'graphlab'}-front`)
  return config
}
