import queryString from 'query-string'
import urlJoin from 'url-join'

import API from './API'
import authAPI from './authAPI'

class SearchAPI extends API {
  constructor() {
    super()
    this.resource = 'search'
  }

  // eslint-disable-next-line no-console
  searchByKeyValue(key, value, callback = console.log) {
    fetch(urlJoin(this.getResourceURL(), '?', queryString.stringify({ key: value })), {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => res.json())
      .then(callback)
    // eslint-disable-next-line no-console
      .catch(console.log)
  }
}

export default new SearchAPI()
