import { AuthenticationContext } from 'react-adal'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'

import authAPI from './api/authAPI'
import neo4jAPI from './api/neo4jAPI'
import queryAPI from './api/queryAPI'
import searchAPI from './api/searchAPI'
import setSentry from './sentry'

const configGetParams = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'GET',
}

const configPath = '/config.json'

export function initializeAzureProvider(setEnv) {
  fetch(configPath, configGetParams)
    .then((res) => res.json())
    .then((config) => {
      const authContext = new AuthenticationContext(config.adalConfig)
      setEnv({
        adalConfig: config.adalConfig,
        authContext,
        setEnv,
      })
    })
    // eslint-disable-next-line no-console
    .catch(console.log)
}

export function initializeConfig(authContext, callback = () => {}) {
  fetch(configPath, configGetParams)
    .then((res) => res.json())
    .then((config) => {
      authAPI.setApiURL(config.apiUrl)
      if (authContext) {
        authAPI.setOnLogout(() => {
          if (localStorage.getItem('clientId')) {
            authContext.logOut()
            localStorage.removeItem('clientId')
          } else {
            window.location.reload()
          }
        })
      }
      queryAPI.setApiURL(config.apiUrl)
      neo4jAPI.setApiURL(config.apiUrl)
      searchAPI.setApiURL(config.apiUrl)
      return config
    })
    .then(callback)
    // eslint-disable-next-line no-console
    .catch(console.log)
}

export function initializeGA(callback) {
  fetch(configPath, configGetParams)
    .then((res) => res.json())
    .then((config) => {
      ReactGA.initialize(config.gaTrackingId)
      return config
    })
    .then(callback)
    // eslint-disable-next-line no-console
    .catch(console.log)
}

export function getConfigParameters(callback) {
  fetch(configPath, configGetParams)
    .then((res) => res.json())
    .then(setSentry)
    .then(callback)
    // eslint-disable-next-line no-console
    .catch(console.log)
}

export function isUserAuthenticated() {
  const loggedIn = Cookies.get('loggedIn')
  return !!loggedIn
}

const sentryBreadCrumbDefaults = {
  error: {
    category: 'error',
    level: Sentry.Severity.Error,
    message: 'Some Error',
    timestamp: Date.now() / 1000,
    type: 'error',
  },
  navigation: {
    category: 'navigation',
    timestamp: Date.now() / 1000,
    type: 'navigation',
  },
  query: {
    level: Sentry.Severity.Info,
    timestamp: Date.now() / 1000,
    type: 'query',
  },
}

export function sentryAddBreadcrumb(breadcrumbType = null, breadcrumb = {}) {
  if (breadcrumbType) {
    Sentry.addBreadcrumb({
      ...sentryBreadCrumbDefaults[breadcrumbType],
      ...breadcrumb,
    })
  }
}
