import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as UnholsterLogo } from './assets/unholster.svg'
import { useAzureContext } from './AzureProvider'
import { spaces } from './constants'
import LoginPage from './pages/login'
import { initializeConfig } from './utils'

import './App.css'

const useStyles = makeStyles(() => ({
  logo: {
    margin: spaces.l,
  },
  root: {
    flexGrow: 1,
  },
}))

function UnauthApp({ location, ...props }) {
  const classes = useStyles()
  const [configured, setConfigured] = useState(false)
  const { authContext } = useAzureContext()

  if (!configured) {
    initializeConfig(authContext, () => setConfigured(true))
  }

  useEffect(() => {
    if (location.pathname.length > 1) {
      localStorage.setItem('redirect', location.pathname + location.search)
    }
  }, [])

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Grid
          alignItems="stretch"
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={2}>
            <UnholsterLogo alt="Logo" className={classes.logo} />
          </Grid>
        </Grid>
      </AppBar>
      <LoginPage {...props} />
    </div>
  )
}

export default UnauthApp
