import React from 'react'
import styled from 'styled-components'

import { currencyFormatter } from '../../../utils'
import Property, { DefaultProperties, PropertiesWrapper } from './Property'

const specialFields = [
  'ppu',
  'anio fabricacion',
  'tasacion',
  'ultima transferencia',
  'numero motor',
  'numero chasis',
]

export default function CarProperties({ node }) {
  const {
    anio_fabricacion: anioFabricacion,
    numero_chasis: numeroChasis,
    numero_motor: numeroMotor,
    ppu,
    tasacion,
    ultima_transferencia: ultimaTransferencia,
  } = node
  return (
    <Wrapper>
      {(tasacion ?? false) && (
      <Property
        label="Tasación"
        value={currencyFormatter.format(tasacion)}
      />
      )}
      <Property
        label="Patente"
        value={ppu}
      />
      <Property
        label="Año fabricación"
        value={anioFabricacion}
      />
      <Property
        label="Última transferencia"
        value={parseDate(ultimaTransferencia)}
      />
      {numeroMotor
        && (
          <Property
            label="Número motor"
            value={numeroMotor.toUpperCase()}
          />
        )}
      {numeroChasis && (
      <Property
        label="Número chasis"
        value={numeroChasis.toUpperCase()}
      />
      )}
      <DefaultProperties node={node} specialFields={specialFields} />
    </Wrapper>
  )
}

const Wrapper = styled(PropertiesWrapper)`

`

function parseDate(stringDate) {
  if (stringDate.length !== 8) {
    return stringDate
  }
  const year = stringDate.slice(0, 4)
  const month = stringDate.slice(4, 6)
  const day = stringDate.slice(6)
  return `${year}-${month}-${day}`
}
