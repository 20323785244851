import React from 'react'
import styled from 'styled-components'

import { ageFormatter, currencyFormatter, rutFormatter } from '../../../utils'
import Property, { DefaultProperties, PropertiesWrapper } from './Property'

const specialFields = [
  'avaluo',
  'decil avaluo',
  'edad',
  'fecha nacimiento estimada',
  'rut',
]

function PeopleProperties({ node }) {
  const {
    avaluo,
    decil_avaluo: decilAvaluo,
    edad,
    fecha_nacimiento_estimada: fechaNacimientoEstimada,
    rut,
  } = node

  return (
    <Wrapper>
      {((avaluo ?? false) || avaluo === 0) && (
        <Property
          label="Avalúo"
          value={currencyFormatter.format(avaluo)}
        />
      )}
      {fechaNacimientoEstimada && (
        <Property
          label="Tipo de fecha de nacimiento"
          value={fechaNacimientoEstimada === 'true' ? 'Estimada' : 'Efectiva'}
        />
      )}
      {decilAvaluo && (
        <Property
          label="Decil Avalúo"
          value={decilAvaluo}
        />
      )}
      {rut && (
        <Property label="Rut" value={rutFormatter(rut.toString()).toUpperCase()} />
      )}
      {((edad ?? false) || edad === 0) && (
        <Property label="Edad" value={ageFormatter.format(edad)} />
      )}
      <DefaultProperties node={node} specialFields={specialFields} />
    </Wrapper>

  )
}

const Wrapper = styled(PropertiesWrapper)`
`

export default PeopleProperties
