import React, { useEffect, useState } from 'react'
import {
  Document, Font, Image, Page, PDFViewer, Text, View,
} from '@react-pdf/renderer'
import moment from 'moment'

import { hankenGroteskBold, hankenGroteskRegular } from '../../../../assets/fonts/HankenGrotesk'
import ResumeIconPng from '../../../../assets/resume.png'
import { getConfigParameters } from '../../../../utils'
import { getIcons, nodeProperties } from '../../../utils'
import getJudiciaryComponents from './judiciary'
import getOfficialDiaryComponents from './official-diary'
import styles from './styles'

import 'moment/locale/es'

moment.locale('es')

const alertComponents = [
  {
    getComponent: getJudiciaryComponents,
    tag: 'pgen_causas',
  },
  {
    getComponent: getOfficialDiaryComponents,
    tag: 'pgen_diario_oficial',
  },
]

export default function Reports({ alerts, history, node }) {
  const [serviceName, setServiceName] = useState(false)

  useEffect(() => {
    if (!serviceName && serviceName !== '') {
      getConfigParameters((params) => setServiceName(params.serviceName) || '')
    }
  }, [serviceName])

  const viaLocation = history && history.location.state
  const resolvedAlerts = alerts || (viaLocation && viaLocation.alerts)
  const resolvedNode = node || (viaLocation && JSON.parse(viaLocation.node))

  const alertTags = resolvedAlerts.map((d) => d.query.key)

  const selectedAlertComponents = alertComponents
    .filter((a) => alertTags.includes(a.tag))
    .map((a) => a.getComponent(resolvedAlerts))

  const resumeComponents = selectedAlertComponents.flatMap((ac) => ac.resume || [])
  const mainComponents = selectedAlertComponents.map((ac) => ac.main)
  const nodeIconSource = getIcons(resolvedNode) && getIcons(resolvedNode).png

  const pdf = selectedAlertComponents.length > 0 && resolvedNode && (
    <Document>
      <Page size="Letter" style={styles.general}>
        <View style={styles.header}>
          <Text>
            Informe
            {' '}
            {serviceName}
          </Text>
          <Text>
            {moment().format('DD-MM-YYYY k:mm:ss')}
          </Text>
        </View>
        <View style={styles.sectionTitle}>
          {nodeIconSource
            && <Image src={getIcons(resolvedNode).png} style={styles.sourceTitle.icon} />}
          <Text>
            {resolvedNode.label}
          </Text>
        </View>
        <View style={styles.nodeProperties}>
          {nodeProperties(resolvedNode).map((p) => (
            <View key={p.name} style={styles.property}>
              <Text style={styles.boldText}>{p.name}</Text>
              <Text>{p.value}</Text>
            </View>
          ))}
        </View>
        {resumeComponents.length > 0 && (
          <View>
            <View style={styles.subSectionTitle}>
              <Image src={ResumeIconPng} style={styles.sourceTitle.icon} />
              <Text>
                Resumen
              </Text>
            </View>
            <View style={styles.nodeProperties}>
              {resumeComponents}
            </View>
          </View>
        )}
        {mainComponents}
      </Page>
    </Document>
  )

  return viaLocation
    ? (
      <PDFViewer height="800px" width="100%">
        {pdf}
      </PDFViewer>
    ) : pdf
}

Font.register({
  family: 'HankenGrotesk',
  fonts: [
    { src: hankenGroteskRegular },
    { fontWeight: '700', src: hankenGroteskBold },
  ],
})
