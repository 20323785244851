import React from 'react'
import styled from 'styled-components'

import { currencyFormatter, rutFormatter } from '../../../utils'
import Property, { DefaultProperties, PropertiesWrapper } from './Property'

const specialFields = [
  'rut',
  'avaluo total',
  'avaluo exento',
  'anio exencion',
  'lonlat',
  'location',
]

export default function RealStateProperties({ node }) {
  const {
    anio_exencion: anioExcencion,
    avaluo_exento: avaluoExento,
    avaluo_total: avaluoTotal,
    location,
    rut,
  } = node
  let lat
  let lon
  if (location) {
    const { coordinates } = location
    const [longitud, latitud] = coordinates || [undefined, undefined]
    lat = latitud
    lon = longitud
  }
  return (
    <Wrapper>
      {rut && (
      <Property
        label="Rut"
        value={rutFormatter(rut.toString()).toUpperCase()}
      />
      )}
      <Property
        label="Año exención"
        value={anioExcencion}
      />
      {((avaluoTotal ?? false) || avaluoTotal === 0) && (
      <Property
        label="Avalúo total"
        value={currencyFormatter.format(avaluoTotal)}
      />
      )}
      {((avaluoExento ?? false) || avaluoExento === 0) && (
      <Property
        label="Avalúo exento"
        value={currencyFormatter.format(avaluoExento)}
      />
      )}
      { lon && lat && (
      <Property
        label="Longitud/Latitud"
        value={`${lon}, ${lat}`}
      />
      )}
      <DefaultProperties node={node} specialFields={specialFields} />
    </Wrapper>
  )
}

const Wrapper = styled(PropertiesWrapper)`

`
