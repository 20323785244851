import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { ReactComponent as ErrorImageBase } from '../assets/not_found.svg'
import { colors } from '../constants'

const useStyles = makeStyles(() => ({
  backButton: {
    padding: '0.5rem 2.5rem',
  },
  errorContainer: {
    background: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    height: `${window.innerHeight - 260}px`,
  },
  graphHeader: {
    color: '#DE4242',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
  },
  graphHeaderContainer: {
    paddingTop: '3rem',
  },
  messageContainer: {
    paddingTop: '5rem',
  },
  messageText: {
    color: '#979797',
    display: 'inline-block',
    fontFamily: 'HankenGroteskRegular',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    lineHeight: '27px',
    marginBottom: '1.5rem',
    maxWidth: '700px',
    whiteSpace: 'pre-wrap',
  },
  normalHeader: {
    color: '#92B6C7',
    fontSize: '86px',
    fontWeight: 'bold',
    letterSpacing: '1.49px',
    lineHeight: '27px',
    marginBottom: '4rem',
  },
  textMessageContainer: {
    textAlign: 'center',
  },
}))

export default function ErrorView({ errorMessage }) {
  const classes = useStyles()

  return (
    <div className={classes.errorContainer}>
      <GeneralError messages={errorMessage} />
    </div>
  )
}

function GeneralError({ messages = [] }) {
  const singleMessage = messages.length > 1
  return (
    <GenerarErrorWrapper>
      <ErrorImage />
      <MessageSection>
        <Title>{singleMessage ? '¡Ha ocurrido un error!' : '¡Algo ocurrió!'}</Title>
        {singleMessage && <SubTitle>Por favor intente ingresar la consulta nuevamente</SubTitle>}
        <Info>
          {singleMessage
            ? (
              <MessageList type="1">
                {messages.map((message, index) => (
                  <Message
                    dangerouslySetInnerHTML={{ __html: message }}
                    key={message.substring(0, 10) + index}
                  />
                ))}
              </MessageList>
            ) : <IsolatedMessage dangerouslySetInnerHTML={{ __html: messages[0] }} />}
        </Info>
      </MessageSection>
    </GenerarErrorWrapper>
  )
}

const GenerarErrorWrapper = styled.div`
    display: grid;
    grid: 1fr / min-content auto;
    height: 100%;
    width: 100%;
    background: white;
    align-items: center;
    gap: 48px;
    padding: 48px;
    box-sizing: border-box;
`

const ErrorImage = styled(ErrorImageBase)``

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
`
const SubTitle = styled.div`
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
`

const MessageSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    justify-content: center;
`

const Info = styled.div`
    margin-top: 32px;`

const Message = styled.li`
    padding-left: 10px;
    line-height: 18px;
    &::marker {
        font-weight: 700;
        font-size: 16px;
        color: black;
    }
`

const IsolatedMessage = styled.div`
    font-weight: 300;
    color: ${colors.principalFont};
`

const MessageList = styled.ol`
    padding-left: 20px;
    font-weight: 300;
    color: ${colors.principalFont};
    > li + li {
        margin-top: 6px;
    }
`
