import ReactDOM from 'react-dom'
import { saveAs } from 'file-saver'
import jsPDF from 'jspdf'
import mustache from 'mustache'
import { saveSvgAsPng, svgAsPngUri } from 'save-svg-as-png'
import XLSX from 'xlsx'

import { colors, graphHiddenProperties, iconRules } from '../constants'

export const checkNodeType = (node, type) => (node ? node.types.includes(type) : false)

export const titleCase = (sentence) => {
  const formattedSentence = sentence.toLowerCase().replace(/_/g, ' ')
  return formattedSentence[0].toUpperCase() + formattedSentence.slice(1)
}

export const getIcons = (node) => iconRules.find((r) => node && node.types.includes(r.key))

export const nodeProperties = (
  node,
  hiddenPropertiesList = graphHiddenProperties,
) => Object.entries(node)
  .filter(([k, _v]) => !hiddenPropertiesList.includes(k))
  .map(([k, v]) => ({ name: titleCase(k), value: v }))

export function truncate(number, decimals = 0) {
  const multiplier = 10 ** decimals
  return Math.trunc(number * multiplier) / multiplier
}

function csv2Blob(data, name) {
  const separator = ','
  const endLine = '\n'
  let csv = data.header.join(separator) + endLine
  csv += data.rows.map((row) => row.join(separator)).join(endLine)
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  saveAs(blob, `${name || 'download'}.csv`)
}

function xlsx2Blob(data, name) {
  const table = [data.header]
  data.rows.forEach((row) => table.push(row))
  const worksheet = XLSX.utils.aoa_to_sheet(table)
  const newWorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(newWorkBook, worksheet)
  const wopts = { bookSST: false, bookType: 'xlsx', type: 'array' }
  const wbout = XLSX.write(newWorkBook, wopts)
  const blob = new Blob([wbout], { type: 'application/octet-stream' })
  saveAs(blob, `${name || 'download'}.xlsx`)
}

async function svg2png(svg, name) {
  saveSvgAsPng(svg, `${name}.png`, { scale: 6 })
}

async function svg2pdf(svg, name) {
  svgAsPngUri(svg, {
    scale: 4,
  }).then((uri) => {
    const image = document.createElement('img')
    image.addEventListener('load', () => {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('l', 'pt', [image.width, image.height])
      doc.addImage(uri, 'PNG', 0, 0, image.width, image.height, undefined, 'FAST')
      doc.save(`${name || 'download'}.pdf`)
    })
    image.src = uri
  })
}

const type2Blob = {
  csv: csv2Blob,
  png: svg2png,
  svg: svg2pdf,
  xlsx: xlsx2Blob,
}
export const downloadFile = async (data, name, type) => type2Blob[type](data, name)

export function getMustacheVariables(template) {
  const notModifiableVariablesByInput = ['minorsEnabled', 'date']

  if (!template || !Array.isArray(notModifiableVariablesByInput)) {
    return []
  }

  const lastMustacheStartIndex = template.lastIndexOf('{{')
  const lastMustacheEndIndex = template.lastIndexOf('}}')
  const currentTemplate = lastMustacheStartIndex > lastMustacheEndIndex
    ? template.substring(0, lastMustacheStartIndex)
    : template
  try {
    return [
      ...new Set(
        mustache.parse(currentTemplate)
          .filter((v) => v[0] === 'name' && !notModifiableVariablesByInput.includes(v[1]))
          .map((v) => v[1]),
      ),
    ]
  } catch (error) {
    return []
  }
}

function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}

export function rutFormatter(value) {
  if (!Number.isInteger(parseInt(value, 10))) {
    return null
  }
  const numeralRut = parseInt(value, 10)
  return `${numeralRut.toLocaleString('es')}-${calculateDV(value)}`
}

export function capitalize(string) {
  const firstChar = string.charAt(0)
  const otherChars = string.slice(1)
  return `${firstChar.toUpperCase()}${otherChars.toLowerCase()}`
}

export function capitalizePerWord(string) {
  if (typeof string !== 'string') {
    return string
  }
  if (string.includes(' ')) {
    return string.split(' ').map((word) => capitalize(word)).join(' ')
  }
  return capitalize(string)
}

export const ageFormatter = Intl.NumberFormat('es-CL', { maximumFractionDigits: 0 })

export const currencyFormatter = Intl.NumberFormat('es-CL', { currency: 'CLP', maximumFractionDigits: 0, style: 'currency' })

function calculateDV(numeralRut) {
  const digits = numeralRut.split('').reverse()
  let multiple = 2
  const sum = digits.reduce((acc, digit) => {
    const numDigit = parseInt(digit, 10)
    const index = multiple * numDigit
    if (multiple < 7) {
      multiple += 1
    } else {
      multiple = 2
    }
    return acc + index
  }, 0)

  const calculatedDV = 11 - (sum % 11)
  if (calculatedDV === 10) return 'k'
  if (calculatedDV === 11) return '0'
  return calculatedDV.toString()
}

export function processNeo4jTable(response) {
  const result = response.results[0]
  const newColumns = []
  const hasRuts = []
  const hasRazons = []
  result.columns.forEach((column, index) => {
    const hasRut = result.data.some(
      (element) => isObject(element.row[index]) && element.row[index].rut,
    )
    const hasRazon = result.data.some(
      (element) => isObject(element.row[index]) && element.row[index].razon_social,
    )
    if (hasRut) {
      newColumns.push({
        label: `Rut ${column}`,
        options: {
          customBodyRender: rutFormatter,
        },
      })
      hasRuts.push(index)
    }
    if (hasRazon) {
      newColumns.push(`Razón Social ${column}`)
      hasRazons.push(index)
    }
    if (!hasRazon && !hasRut) {
      newColumns.push(column)
    }
  })

  const data = []
  result.data.forEach((element) => {
    const newRow = []
    element.row.forEach((value, index) => {
      if (hasRuts.includes(index)) {
        newRow.push(value.rut)
      }
      if (hasRazons.includes(index)) {
        newRow.push(value.razon_social)
      }
      if (!hasRazons.includes(index) && !hasRuts.includes(index)) {
        newRow.push(isObject(value) ? element.meta[index].id : value)
      }
    })
    data.push(newRow)
  })
  return { columns: newColumns, data }
}

export const defaultTableOptions = {
  customSearch: (searchQuery, currentRow) => {
    let founded = false
    const currentSearchQuery = (searchQuery.length && searchQuery[0] === ' ') ? searchQuery.slice(1) : searchQuery
    currentRow.forEach((cell) => {
      if (cell && cell.toString().toLowerCase().indexOf(currentSearchQuery.toLowerCase()) >= 0) {
        founded = true
      }
    })
    return founded
  },
  download: false,
  filter: false,
  print: false,
  rowsPerPage: 5,
  rowsPerPageOptions: [5, 50, 100],
  searchText: ' ',
  selectableRows: 'none',
  textLabels: {
    body: {
      toolTip: 'Ordenar',
    },
    pagination: {
      displayRows: 'de',
      rowsPerPage: 'Filas por página:',
    },
    toolbar: {
      downloadCsv: 'Descargar CSV',
      search: 'Buscar',
      viewColumns: 'Ver Columnas',
    },
  },
  viewColumns: false,
}

export function ESCSearchInputWorkaround(ref) {
  return (event) => {
    if (event.keyCode === 27) {
      setTimeout(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const searchBtn = ReactDOM.findDOMNode(ref.current).querySelector('button[aria-label="Buscar"]')
        searchBtn.click()
        searchBtn.style.display = 'none'
      }, 1)
    }
  }
}

export const defaultMuiTableTheme = {
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
      },
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#F3F5F7',
        },
      },
    },
    MUIDataTableHeadCell: {
      data: {
        color: 'white !important',
      },
      root: {
        backgroundColor: '#6F8294 !important',
        color: 'white',
        textAlign: 'center',
      },
    },
    MUIDataTableSearch: {
      clearIcon: {
        display: 'none',
      },
    },
    MUIDataTableToolbar: {
      icon: {
        '&:hover': {
          color: '#5C7B8E',
        },
        '&[aria-label="Buscar"]': {
          display: 'none',
        },
      },
      iconActive: {
        color: '#5C7B8E',
      },
      root: {
        backgroundColor: colors.backgroundGrey,
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid #5C7B8E',
        },
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: 'white !important',
      },
    },
  },
}
