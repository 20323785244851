import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  boldText: {
    fontSize: '12px',
    fontWeight: '700',
  },
  general: {
    fontFamily: 'HankenGroteskRegular',
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '14px',
    fontWeight: '300',
    justifyContent: 'space-between',
    padding: '12px',
  },
  nodeProperties: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '12px',
  },
  property: {
    fontSize: '12px',
    padding: '6px',
    width: '33.33%',
  },
  sectionTitle: {
    backgroundColor: '#ECEDF3',
    color: '#404245',
    flexDirection: 'row',
    fontSize: '16px',
    fontWeight: '700',
    padding: '12px',
  },
  sourceContent: {
    width: '66.66%',
  },
  sourceLayout: {
    flexDirection: 'row',
    fontSize: '12px',
    padding: '12px',
  },
  sourceTitle: {
    icon: {
      height: '20px',
      marginRight: '6px',
      width: '20px',
    },
    padding: '12px 0',
    width: '33.33%',
  },
  subSectionTitle: {
    backgroundColor: '#ECEDF3',
    color: '#404245',
    flexDirection: 'row',
    fontSize: '14px',
    fontWeight: '700',
    padding: '12px',
  },
})
