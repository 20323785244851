import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as moment from 'moment'
import styled from 'styled-components'

import { ReactComponent as OfficialDiaryIconBase } from '../../../../assets/official_diary.svg'
import { titleCase } from '../../../utils'
import { Accordion, AccordionSummary } from './common'

moment.locale('es')

export default function OfficialDiary({ data }) {
  const rows = data.result.results[0].data
    .map((d) => d.row[0])
    .sort((r) => r.fecha_publicacion)
    .reverse()

  return rows.length > 0 && (
    <OfficialDiaryWrapper>
      <SectionTitle
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="diario-oficial-accordion"
      >
        <OfficialDiaryIcon />
        Diario Oficial
      </SectionTitle>
      <Causas>
        {rows.map((r) => (
          <Causa key={r.numero}>
            <RoleCode>
              CVC-
              {r.numero}
              <br />
              <Date>{moment(r.fecha_publicacion).format('DD/MM/YY')}</Date>
            </RoleCode>
            <RoleStatus>
              {titleCase(r.accion)}
            </RoleStatus>
          </Causa>
        ))}
      </Causas>
    </OfficialDiaryWrapper>
  )
}

const SectionTitle = styled(AccordionSummary)``

const RoleCode = styled.span`
`

const RoleStatus = styled.span`
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
`

const Causa = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
`

const Causas = styled.div`
  padding-left: 28px;
  font-size: 14px;
`

const OfficialDiaryWrapper = styled(Accordion)``

const OfficialDiaryIcon = styled(OfficialDiaryIconBase)`
  margin-right: 8px;
`

const Date = styled.div`
  font-size: 12px;
`
