import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs as BreadcrumbsBase } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import styled from 'styled-components'

export default function BreadCrumbs({ crumbs }) {
  return crumbs && crumbs.length > 0 && (
    <Breadcrumbs aria-label="Breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        {
            crumbs.map((crumb, index) => {
              if (crumb.route) {
                return (
                  <Link key={`breadcrumb-${index}`} to={crumb.route}>
                    {crumb.label}
                  </Link>
                )
              }
              return (
                <Typography color="textPrimary" key={`breadcrumb-${index}`}>{crumb.label}</Typography>
              )
            })
        }
    </Breadcrumbs>
  )
}

const Breadcrumbs = styled(BreadcrumbsBase)`
    li p, a {
        font-size: 14px;
        font-weight: 400;
    }
    li:last-child > * {
        font-weight: 700 !important;
    }
`
