import React from 'react'
import { FiDownload } from 'react-icons/fi'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from '@material-ui/icons/Refresh'
import MUIDataTable from 'mui-datatables'

import { defaultMuiTableTheme, defaultTableOptions, downloadFile } from './utils'

const getMuiTheme = () => createTheme({
  ...defaultMuiTableTheme,
  palette: {
    primary: {
      main: '#048CC2',
    },
    secondary: {
      main: '#71B34C',
    },
  },
})

const useStyles = makeStyles(() => ({
  buttonIcon: {
    fontSize: '20px',
    paddingRight: '0.5rem',
  },
  refreshIcon: {
    color: 'white',
    fontSize: '20px',
  },
  toolbarButton: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    lineHeight: '19px',
    marginLeft: '0.5rem',
    minWidth: '56px',
    padding: '6px 10px',
  },
}))

export default function GenericTable(props) {
  const {
    columns, data, name, onRefresh, options,
  } = props
  const classes = useStyles()

  const downloadTable = (type) => () => {
    const header = columns.map((column) => {
      if (typeof column === 'string') {
        return column
      }
      return column && column.label ? column.label : null
    }).filter((column) => column !== null)
    downloadFile({ header, rows: data }, name, type)
  }

  const defaultOptions = {
    ...defaultTableOptions,
    customToolbar: () => (
      <>
        <Tooltip placement="top" title="Actualizar datos">
          <Button className={classes.toolbarButton} color="secondary" onClick={onRefresh} variant="contained">
            <RefreshIcon className={classes.refreshIcon} />
          </Button>
        </Tooltip>
        <Button className={classes.toolbarButton} color="primary" onClick={downloadTable('xlsx')} variant="contained">
          <FiDownload className={classes.buttonIcon} />
          XLSX
        </Button>
        <Button className={classes.toolbarButton} color="primary" onClick={downloadTable('csv')} variant="contained">
          <FiDownload className={classes.buttonIcon} />
          CSV
        </Button>
      </>
    ),
    pagination: data.length > 5,
    textLabels: {
      ...defaultTableOptions.textLabels,
      body: {
        ...defaultTableOptions.textLabels.body,
        noMatch: data.length === 0 ? <CircularProgress /> : 'No se encuentran valores para cargar',
      },
    },
  }

  const tableOptions = { ...defaultOptions, ...options }

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        columns={columns}
        data={data}
        options={tableOptions}
      />
    </MuiThemeProvider>
  )
}
