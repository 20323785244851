import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { Redirect, Route, Switch } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import ExitIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'

import AuthAPI from './api/authAPI'
import background from './assets/fondo.jpg'
import { ReactComponent as UnholsterLogo } from './assets/unholster.svg'
import { useAzureContext } from './AzureProvider'
import { spaces } from './constants'
import QueriesPage from './pages/queries'
import { initializeConfig, sentryAddBreadcrumb } from './utils'

import './App.css'

const useStyles = makeStyles({
  appBar: {
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)',
    padding: `${spaces.m} ${spaces.m} ${spaces.m} ${spaces.l}`,
  },
  root: {
    backgroundImage: (props) => (props.showBackground ? `url(${background})` : 'none'),
    backgroundSize: 'cover',
    flexGrow: 1,
  },
  userName: {
    fontWeight: 'normal',
    marginRight: spaces.s,
  },
})

const tabs = [
  {
    component: QueriesPage,
    label: 'Catálogo de Consultas',
    value: '/consultas',
  },
]

function getUsername() {
  const firstName = localStorage.getItem('first_name') || ''
  const lastName = localStorage.getItem('last_name') || ''
  return `${firstName} ${lastName}`
}

function App({ history }) {
  const [configuration, setConfiguration] = useState()
  const showBackground = history.location.pathname === '/buscador'
  const classes = useStyles({ showBackground })
  const { authContext } = useAzureContext()
  const showAdmin = localStorage.getItem('show_admin') === 'true'

  if (!configuration) {
    initializeConfig(authContext, setConfiguration)
  }

  const user = getUsername()

  const handleLogout = () => AuthAPI.logout(() => history.push('/'))

  const currentPathRef = useRef(window.location.pathname)
  useEffect(() => {
    const currentPath = window.location.pathname
    sentryAddBreadcrumb('navigation', {
      data: {
        from: currentPathRef.current,
        to: currentPath,
      },
    })
    currentPathRef.current = currentPath
    ReactGA.set({ page: currentPath }) // Update the user's current page
    ReactGA.pageview(currentPath + window.location.search) // Record a pageview for the given page
    ReactGA.event({
      action: 'User changed page',
      category: 'Change Page',
      label: currentPath,
    })
  }, [])

  return configuration
    ? (
      <div className={classes.root} style={{}}>
        <AppBar className={classes.appBar} position="static">
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
          >
            <UnholsterLogo alt="Logo" />
            <Grid
              alignItems="center"
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs
            >
              <h5 className={classes.userName}>{user}</h5>
              {showAdmin && (
                <Button
                  onClick={() => { window.location.href = configuration.adminUrl }}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    marginLeft: 15,
                    marginRight: 15,
                  }}
                  variant="text"
                >
                  <SettingsIcon style={{ marginRight: 10 }} />
                  <h5
                    style={{
                      color: '#000',
                      fontSize: 14,
                      fontWeight: 'normal',
                      margin: 'auto',
                      textTransform: 'none',
                    }}
                  >
                    Administración
                  </h5>
                </Button>
              )}
              <Tooltip aria-label="Salir" title="Salir">
                <IconButton onClick={handleLogout}>
                  <ExitIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </AppBar>
        <Switch>
          {tabs.map((tab, index) => (
            <Route component={tab.component} key={`router-${index}`} path={tab.value} />
          ))}
          <Redirect exact from="/" to={tabs[0].value} />
        </Switch>
      </div>
    ) : <CircularProgress />
}

export default App
