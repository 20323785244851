import React from 'react'
import styled from 'styled-components'

import { ReactComponent as LegendArrowBase } from '../../../assets/legend_arrow.svg'
import { ReactComponent as LegendCircleBase } from '../../../assets/legend_circle.svg'

const getLegends = (nodes, links) => [
  {
    component: (
      <Legend key="Empresa">
        <LegendCircle bordercolor="#3892F7" />
        Empresa
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('Empresa')).length > 0,
  },
  {
    component: (
      <Legend key="Persona">
        <LegendCircle bordercolor="#F4C060" />
        Persona
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('Persona')).length > 0,
  },
  {
    component: (
      <Legend key="Actividad económica">
        <LegendCircle bordercolor="#048CC2" />
        Actividad económica
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('Actividad')).length > 0,
  },
  {
    component: (
      <Legend key="Causa">
        <LegendCircle bordercolor="purple" />
        Causa
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('Causa')).length > 0,
  },
  {
    component: (
      <Legend key="Holding">
        <LegendCircle bordercolor="green" />
        Holding
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('Holding')).length > 0,
  },
  {
    component: (
      <Legend key="Alerta">
        <LegendCircle bordercolor="#E6745C" fillcolor="#E6745C" />
        Alerta
      </Legend>
    ),
    show: nodes.filter((n) => n.causas_vigentes === 'Sí').length > 0,
  },
  {
    component: (
      <Legend key="Descendientes">
        <LegendArrow color="#E6745C" />
        Descendientes
      </Legend>
    ),
    show: links.filter((l) => l._categoria === 'salida').length > 0,
  },
  {
    component: (
      <Legend key="Ascendientes">
        <LegendArrow color="#6ea062" />
        Ascendientes
      </Legend>
    ),
    show: links.filter((l) => l._categoria === 'entrada').length > 0,
  },
  {
    component: (
      <Legend key="Auto">
        <LegendCircle bordercolor="#E41B17" />
        Auto
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('Auto')).length > 0,
  },
  {
    component: (
      <Legend key="BienRaiz">
        <LegendCircle bordercolor="#27AE60" />
        Bien Raíz
      </Legend>
    ),
    show: nodes.filter((n) => n.types.includes('BienRaiz')).length > 0,
  },
]

export default function Legends({
  className,
  links,
  nodes,
}) {
  const toShowLegends = getLegends(nodes, links).filter((l) => l.show)
  return toShowLegends.length > 0
    ? (
      <LegendsWrapper className={className}>
        {toShowLegends.map((l) => l.component)}
      </LegendsWrapper>
    ) : null
}

const LegendsWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 12px;
    padding: 12px;
    > * + * {
        margin-left: 24px;
    }
`
const LegendArrow = styled(LegendArrowBase)`
    margin-right: 3px;
    path {
        stroke: ${(props) => props.color};
        fill: ${(props) => props.color};
    }
`

const LegendCircle = styled(LegendCircleBase)`
    margin-right: 3px;
    fill: ${(props) => props.fillcolor};
    circle {
        stroke: ${(props) => props.bordercolor};
    }
`

const Legend = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
`
