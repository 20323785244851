import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'

import { ReactComponent as JudiciaryIconBase } from '../../../../assets/judiciary.svg'
import { Accordion, AccordionSummary } from './common'

const labelMap = (originalLabel) => {
  const labels = {
    'DDO.': 'Demandado',
    'DTE.': 'Demandante',
  }

  return labels[originalLabel] || originalLabel
}

export default function PoderJudicial({ data }) {
  const rows = data.result.results[0].data
    .map((d) => ({ role: d.row[1], status: labelMap(d.row[0]) }))

  return rows.length > 0 && (
    <PoderJudicialWrapper>
      <SectionTitle
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <JudiciaryIcon />
        Poder Judicial
      </SectionTitle>
      <Causas>
        {rows.map((r) => (
          <Causa key={r.role}>
            <RoleCode>
              {r.role}
            </RoleCode>
            <RoleStatus>
              {r.status}
            </RoleStatus>
          </Causa>
        ))}
      </Causas>
    </PoderJudicialWrapper>
  )
}

const SectionTitle = styled(AccordionSummary)``

const RoleCode = styled.span``

const RoleStatus = styled.span`
  font-weight: 700;
`

const Causa = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
`

const Causas = styled.div`
  padding-left: 28px;
  font-size: 14px;
`

const PoderJudicialWrapper = styled(Accordion)`
`

const JudiciaryIcon = styled(JudiciaryIconBase)`
  margin-right: 8px;
`
