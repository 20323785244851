import urlJoin from 'url-join'

export default class API {
  constructor() {
    this.apiURL = null
    this.resource = null
  }

  setApiURL(value) {
    this.apiURL = value
  }

  getResourceURL() {
    return `${urlJoin(this.apiURL, this.resource)}/`
  }
}
