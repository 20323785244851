import Cookies from 'js-cookie'
import urlJoin from 'url-join'

import API from './API'
import authAPI from './authAPI'

const applicationJson = 'application/json'
const resJsonCallBack = (res, okCallback, errorCallback) => {
  if (res.ok) {
    res.json().then(okCallback)
  } else {
    errorCallback(res)
  }
  return res
}

export class QueryAPI extends API {
  constructor() {
    super()
    this.resource = 'queries'
  }

  getAll(callback) {
    fetch(this.getResourceURL(), {
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
      },
      method: 'GET',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => res.json())
      .then((data) => callback(data))
    // eslint-disable-next-line no-console
      .catch(console.log)
  }

  // eslint-disable-next-line no-console
  get(id, okCallback = console.log, errorCallback = console.log) {
    fetch(`${urlJoin(this.getResourceURL(), id.toString())}/`, {
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
      },
      method: 'GET',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => resJsonCallBack(res, okCallback, errorCallback))
      .catch(errorCallback)
  }

  // eslint-disable-next-line no-console
  create(query, okCallback = console.log, errorCallback = console.log) {
    const csrf = Cookies.get('csrftoken')
    fetch(this.getResourceURL(), {
      body: JSON.stringify(query),
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
        'X-CSRFToken': csrf,
      },
      method: 'POST',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => resJsonCallBack(res, okCallback, errorCallback))
      .catch(errorCallback)
  }

  // eslint-disable-next-line no-console
  update(query, okCallback = console.log, errorCallback = console.log) {
    const csrf = Cookies.get('csrftoken')
    fetch(`${urlJoin(this.getResourceURL(), query.id.toString())}/`, {
      body: JSON.stringify(query),
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
        'X-CSRFToken': csrf,
      },
      method: 'PUT',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => resJsonCallBack(res, okCallback, errorCallback))
      .catch(errorCallback)
  }

  // eslint-disable-next-line no-console
  delete(id, okCallback = console.log, errorCallback = console.log) {
    const csrf = Cookies.get('csrftoken')
    fetch(`${urlJoin(this.getResourceURL(), id.toString())}/`, {
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
        'X-CSRFToken': csrf,
      },
      method: 'DELETE',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => {
        if (res.ok) {
          okCallback(res)
        } else {
          errorCallback(res)
        }
        return res
      })
      .catch(errorCallback)
  }

  static newQuery() {
    return {
      creator: null,
      description: null,
      id: null,
      name: null,
      neo4jQuery: null,
      neo4jQuery_default_values: {},
      type: 'G',
    }
  }
}

export default new QueryAPI()
