import ReactGA from 'react-ga'
import Cookies from 'js-cookie'
import urlJoin from 'url-join'

import API from './API'
import loadDataToLocalStorage from './utils'

const applicationJson = 'application/json'

const loginFetch = (apiUrl, path, body, csrf) => (
  fetch(urlJoin(apiUrl, path), {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      Accept: applicationJson,
      'Content-Type': applicationJson,
      'X-CSRFToken': csrf,
    },
    method: 'POST',
  })
)

class AuthAPI extends API {
  constructor() {
    super()
    this.onLogout = () => {
      Cookies.remove('loggedIn')
      window.location.reload()
    }
    this.logoutOnAuthError = this.logoutOnAuthError.bind(this)
  }

  setOnLogout(func) {
    this.onLogout = () => {
      Cookies.remove('loggedIn')
      func()
    }
  }

  logoutOnAuthError(response) {
    if (response.status === 401 || response.status === 403) {
      this.onLogout()
    }
    return response
  }

  // eslint-disable-next-line no-console
  loginAzure(token, callback = console.log, errorCallback = console.log) {
    const path = 'loginazure/'
    const body = { token }
    const csrf = Cookies.get('csrftoken')
    loginFetch(this.apiURL, path, body, csrf)
      .then((res) => {
        if (res.ok) {
          ReactGA.event({
            action: 'Azure user logged',
            category: 'Log in',
          })
          localStorage.setItem('clientId', token)
          Cookies.set('loggedIn', true)
          callback(res)
        } else { errorCallback(res) }
      })
    // eslint-disable-next-line no-console
      .catch(console.log)
  }

  // eslint-disable-next-line no-console
  login(user, pass, callback = console.log) {
    const path = 'login/'
    const body = { password: pass, username: user }
    const csrf = Cookies.get('csrftoken')
    loginFetch(this.apiURL, path, body, csrf)
      .then((res) => {
        if (res.ok) {
          ReactGA.event({
            action: 'User logged',
            category: 'Log in',
          })
          Cookies.set('loggedIn', true)
        }
        callback(res)
      })
    // eslint-disable-next-line no-console
      .catch(console.log)
  }

  // eslint-disable-next-line no-console
  logout(okCallback = console.log, errorCallback = console.log) {
    const path = 'logout/'
    const csrf = Cookies.get('csrftoken')
    fetch(urlJoin(this.apiURL, path), {
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
        'X-CSRFToken': csrf,
      },
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          this.onLogout()
          okCallback(res)
        } else {
          errorCallback(res)
        }
        return res
      })
    // eslint-disable-next-line no-console
      .catch(console.log)
  }

  // eslint-disable-next-line no-console
  getUserData(callback = console.log) {
    const path = 'userinfo/'
    fetch(urlJoin(this.apiURL, path), {
      credentials: 'include',
      headers: {
        Accept: applicationJson,
        'Content-Type': applicationJson,
      },
      method: 'GET',
    })
      .then(this.logoutOnAuthError)
      .then((res) => res.json())
      .then(loadDataToLocalStorage)
      .then(callback)
    // eslint-disable-next-line no-console
      .catch(console.log)
  }
}

export default new AuthAPI()
