import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import * as moment from 'moment'

import 'moment/locale/es'

moment.locale('es')

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14)',
  },
  tableDataCell: {
    color: '#6F8294',
    fontSize: '12px',
    letterSpacing: '0.21px',
    lineHeight: '15px',
  },
  tableHeader: {
    '& > tr > th': {
      color: '#6F8294',
      fontSize: '13px',
      letterSpacing: '0.23px',
      lineHeight: '16px',
    },
    backgroundColor: '#E4E4E4',
  },
}))

export default function QueryListVersions({ query }) {
  const classes = useStyles()
  return (
    <Paper className={classes.container}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Historial de cambios</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {query.versions.map((version, index) => (
            <TableRow key={version.id}>
              <TableCell className={classes.tableDataCell} component="th">
                Versión
                {' '}
                {query.version - index}
                {' '}
                -
                {`${version.modifier.first_name || 'Administrador'} ${version.modifier.last_name}`}
                {' '}
                -
                {moment(version.created_at).format('DD MMMM H:mm')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
