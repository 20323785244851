import React, { useEffect, useRef, useState } from 'react'
import { D3Graph, neo4j2D3Graph } from 'react-graphkit'
import { FiDownload } from 'react-icons/fi'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from '@material-ui/icons/Refresh'

import neo4jAPI from '../../../api/neo4jAPI'
import {
  graphAttributes,
  graphBlacklistKeywords,
  graphKeywords,
  mainTypeValues,
  searchLabelsForInputMappedToRut,
  searchLabelsForName,
} from '../../../constants'
import UIStrings from '../../../strings'
import ErrorView from '../../error-view'
import {
  downloadFile, getMustacheVariables, rutFormatter, truncate,
} from '../../utils'
import QueryDefaultValues from '../query-default-values'
import Legend from './legend'
import NodePanel from './node-panel'

import './graph.css'

const getMuiTheme = () => createTheme({
  palette: {
    primary: {
      main: '#048CC2',
    },
    secondary: {
      main: '#71B34C',
    },
  },
})

const useStyles = makeStyles(() => ({
  buttonContainer: {
    marginBottom: '5px',
    textAlign: 'end',
  },
  buttonIcon: {
    fontSize: '20px',
    paddingRight: '0.5rem',
  },
  d3Graph: {
    '& text': {
      fill: 'black',
      fontWeight: '400',
    },
    backgroundColor: 'white',
    width: '100%',
  },
  defaultValuesContainer: {
    paddingTop: '1rem',
  },
  downloadButton: {
    fontFamily: 'HankenGroteskBold',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.28px',
    lineHeight: '19px',
    marginLeft: '0.5rem',
    minWidth: '56px',
    padding: '6px 10px',
  },
  graphContainer: {
    paddingTop: '0.5rem',
    textAlign: 'center',
  },
  refreshIcon: {
    fontSize: '20px',
  },
}))

const linkLabelModifier = (link) => {
  if (link.type.toLowerCase() === 'socio_de') {
    link.label = truncate(link.participacion, 3)
  } else if (link.type.toLowerCase() === 'socio' || link.type.toLowerCase() === 'sociov') {
    link.label = link.participacion
      ? `${truncate(link.participacion, 3)}%`
      : 'SOCIO'
  } else if (link.type.toLowerCase() === 'en_causa') {
    link.label = link.sujeto
  } else {
    link.label = link.type
  }
}

const linkColorModifier = (link) => {
  if (link._categoria === 'entrada') {
    link.classNames = ['red-link']
  } else if (link._categoria === 'salida') {
    link.classNames = ['green-link']
  }
}

const graphConfig = {
  arrowWidth: 1,
  height: window.innerHeight - 304,
  linkModifier: (link) => {
    linkLabelModifier(link)
    linkColorModifier(link)
  },
  tooltip: true,
}

const checkForSearchedNodeByRut = (values, node) => {
  let searchByRut = (
    values[searchLabelsForInputMappedToRut.nombreRut]
    || values[searchLabelsForInputMappedToRut.nombre]
    || values[searchLabelsForInputMappedToRut.rut]
  )

  const isArray = Array.isArray(searchByRut)
  if (searchByRut && !isArray) {
    searchByRut = [searchByRut]
  }
  return searchByRut && (searchByRut.includes(node.rut) || searchByRut.includes(String(node.rut)))
}

const checkForSearchedNodeByName = (values, node) => {
  const valuesName = values[searchLabelsForName.nombre]
  const valuesFatherLastName = values[searchLabelsForName.paterno]
  const valuesMotherLastName = values[searchLabelsForName.materno]

  const searchByName = valuesName && valuesFatherLastName && valuesMotherLastName

  if (searchByName) {
    const nameMatch = node.nombre.toLowerCase().includes(valuesName.toLowerCase())
    const fatherLastNameMatch = node.paterno.toLowerCase() === valuesFatherLastName.toLowerCase()
    const motherLastNameMatch = node.materno.toLowerCase() === valuesMotherLastName.toLowerCase()

    return nameMatch && fatherLastNameMatch && motherLastNameMatch
  }

  return false
}

const checkForSearchedNode = (values, node) => {
  const nodeMatchByRut = checkForSearchedNodeByRut(values, node)
  if (nodeMatchByRut) {
    return nodeMatchByRut
  }

  const nodeMatchByName = checkForSearchedNodeByName(values, node)
  if (nodeMatchByName) {
    return nodeMatchByName
  }

  return false
}

const markSearchedNode = (values, node) => {
  const isSearchedNode = checkForSearchedNode(values, node)

  const nodeType = node.types.filter((type) => mainTypeValues.includes(type))[0]

  if (nodeType && isSearchedNode) {
    node.classNames = [`main-node-${nodeType.toLowerCase()}`]
  }
}

const nodeModifier = (node, values) => {
  node.originalNode = { ...node }

  markSearchedNode(values, node)

  if (node.types.some((type) => type.toLowerCase() === 'contribuyente')) {
    if (node.razon_social && node.razon_social !== '') {
      node.label = node.razon_social
    } else if (node.rut) {
      node.label = rutFormatter(node.rut)
    } else {
      node.label = 'Contribuyente No-Identificado'
    }
  } else if (node.types.some((type) => type.toLowerCase() === 'actividad')) {
    node.label = node.act_econ && node.act_econ !== '' ? node.act_econ : node.cod_act_econ
    // factoring.unholster.com nodes
    node.label = node.desc_acteco && node.desc_acteco !== '' ? node.desc_acteco : node.label
  } else if (node.types.some((type) => type.toLowerCase() === 'grupo')) {
    node.label = node.rut_controlador.toString()
  } else if (node.types.some((type) => type.toLowerCase() === 'familia')) {
    node.label = node.apellidos.toString().replace('-', ' ')
  } else if (node.types.some((type) => type.toLowerCase() === 'personas')) {
    node.label = node.nombre ? node.nombre.toString() : 'Persona No-Identificada'
  } else if (node.types.some((type) => type.toLowerCase() === 'famprobable')) {
    node.label = node.apellidos.toString().replace('_', ' ')
  } else if (node.types.some((type) => type.toLowerCase() === 'causa')) {
    node.label = node.caratulado.toString()
    // factoring.unholster.com nodes
  } else if (node.types.some((type) => type.toLowerCase() === 'persona')) {
    node.label = node.nombre ? node.nombre.toString() : 'Persona No-Identificada'
    if (!values.minorsEnabled && node.edad && node.edad < 18) {
      node.label = 'Persona Menor-de-Edad'
    }
  } else if (node.types.some((type) => type.toLowerCase() === 'empresa')) {
    node.label = node.razon_social ? node.razon_social.toString() : 'Empresa No-Identificada'
  } else if (node.types.some((type) => type.toLowerCase() === 'holding')) {
    node.label = node.nombre ? node.nombre.toString() : 'Holding No-Identificado'
  } else if (node.types.some((type) => type.toLowerCase() === 'registrodo')) {
    node.label = node.numero ? `Diario Oficial CVE-${node.numero.toString()}` : 'Registro Diario Oficial'
  } else if (node.types.some((type) => type.toLowerCase() === 'auto')) {
    node.label = node.ppu ? node.ppu.toString() : 'Auto No-Identificado'
  } else if (node.types.some((type) => type.toLowerCase() === 'bienraiz')) {
    node.label = node.direccion ? node.direccion.toString() : 'Propiedad No-Identificada'
  } else {
    node.label = node.label || node.id
  }
  // Muestra alerta si tiene apariciones en diarioo oficial o tiene causas vigentes
  if (node.causas_vigentes === 'Sí' || node.aparece_en_diario_oficial === 'Sí') {
    node.classNames = ['filled']
  }

  node.types.forEach((type) => {
    const fields = graphAttributes[type.toLowerCase()]
    Object.keys(node).forEach((key) => {
      if ((fields && !graphKeywords.includes(key) && !fields.includes(key))
        || graphBlacklistKeywords.includes(key)) {
        delete node[key]
      }
    })
  })
}

function graph2Table(graph) {
  const nodemap = {}
  let removeKeys = ['classNames', 'label']
  const nodes = graph.nodes.map((node) => {
    const newNode = { ...node }
    removeKeys.forEach((key) => delete newNode[key])
    newNode.types = newNode.types.join(' - ')
    return newNode
  })
  nodes.forEach((node) => { nodemap[node.id] = node })

  removeKeys = ['classNames', 'label', 'source', 'target']
  const srts = []
  graph.links.forEach((link) => {
    const newLink = { ...link }
    srts.push([nodemap[link.source], newLink, nodemap[link.target]])
    removeKeys.forEach((key) => delete newLink[key])
  })

  let headers = [[], [], []]
  srts.forEach((row) => {
    row.forEach((element, index) => {
      Object.keys(element).forEach(
        (key) => (headers[index].includes(key) ? null : headers[index].push(key)),
      )
    })
  })

  const headersGroups = ['Origen', 'Relación', 'Destino']
  headers = headers.flatMap((keys, index) => keys.map((key) => `${key} ${headersGroups[index]}`))
  const data = srts.map((row) => {
    const dataRow = []
    headers.forEach((header) => {
      const [key, indexName] = header.split(' ')
      const index = headersGroups.indexOf(indexName)
      dataRow.push(row[index][key])
    })
    return dataRow
  })
  return { header: headers, rows: data }
}

function getSearchParams() {
  const searchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(searchParams)
}

function getInitialQuery({ location, neo4jProps, query }) {
  const newNeo4jProps = neo4jProps
    || (location.state && location.state.neo4jProps ? location.state.neo4jProps : {})

  const searchParams = getSearchParams()
  return {
    ...query,
    neo4jQuery_default_values: {
      ...query.neo4jQuery_default_values,
      ...searchParams,
      ...newNeo4jProps,
    },
  }
}

const GraphOrError = ({
  className, config, errorMessage, graph,
  graphLoaded, onClick, rightPanelRef, subqueries, ...props
}) => (graphLoaded
  ? (
    <Grid container direction="row" spacing={2}>
      <Grid item xs>
        <D3Graph
          className={className}
          config={config}
          data={graph}
          onClick={onClick}
          onMouseEnter={() => null}
        />
        <Legend links={graph.links} nodes={graph.nodes} />
      </Grid>
      <Grid item>
        <NodePanel ref={rightPanelRef} subqueries={subqueries} {...props} />
      </Grid>
    </Grid>
  ) : <ErrorView errorMessage={errorMessage} />
)

export default function QueryGraph({ onUpdate, ...props }) {
  const [refresh, setRefresh] = useState(false)
  const [graph, setGraph] = useState()
  const [subqueries, setSubqueries] = useState()
  const [errorMessage, setErrorMessage] = useState([UIStrings.graph.notNodes])
  const [validationErrors, setValidationErrors] = useState({})
  const [query, setQuery] = useState(getInitialQuery(props))
  const classes = useStyles()
  const rightPanelRef = useRef(null)

  const setRightPanelNode = (node) => {
    rightPanelRef.current.onNodeChange(node)
  }
  const updateQueryField = (fieldname, value) => {
    const newQuery = {
      ...query,
      [fieldname]: value,
    }
    setQuery(newQuery)
    setGraph(null)
  }

  const downloadGraph = (type) => (_event) => {
    if (type === 'pdf') {
      const svgContainer = document.getElementsByClassName('d3graph')[0]
      const svg = svgContainer.getElementsByTagName('svg')[0]
      downloadFile(svg, query.name, 'svg')
    } else {
      downloadFile(graph2Table(graph), query.name, type)
    }
  }

  useEffect(() => {
    if (query && !graph) {
      neo4jAPI.runQuery(
        query,
        refresh,
        (res) => {
          setSubqueries(res.subqueries)
          const newGraph = neo4j2D3Graph(res.result)
          newGraph.nodes.forEach((node) => nodeModifier(node, res.values))
          newGraph.links.forEach(graphConfig.linkModifier)
          newGraph.links = newGraph.links.filter((link) => link.source !== link.target)
          setGraph(newGraph)
          setRefresh(false)
          setValidationErrors({})
          setErrorMessage([UIStrings.graph.notNodes])
          onUpdate(res.executed_at)
        },
        (res) => {
          setGraph({ nodes: [] })
          setRefresh(false)
          setErrorMessage(UIStrings.server.error)
          setValidationErrors(res)
          onUpdate(null)
        },
      )
    }
  }, [graph, query, refresh, onUpdate])

  const refreshData = () => {
    setGraph(null)
    setRefresh(true)
  }

  const queryVars = query && query.neo4jQuery ? getMustacheVariables(query.neo4jQuery) : []
  const graphLoaded = graph && graph.nodes && graph.nodes.length > 0

  return (
    <>
      <Grid
        alignItems="flex-end"
        className={classes.gridContainer}
        container
        direction="row"
        item
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item md={8} xs={6}>
          {queryVars.length > 0 && (
            <QueryDefaultValues
              className={classes.defaultValuesContainer}
              defaultValues={query.neo4jQuery_default_values}
              onUpdate={(values) => updateQueryField('neo4jQuery_default_values', values)}
              queryVars={queryVars}
              size={4}
              validationErrors={validationErrors}
            />
          )}
        </Grid>
        <Grid className={classes.buttonContainer} item md={4} xs={6}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Tooltip placement="top" title="Actualizar datos">
              <Button className={classes.downloadButton} onClick={refreshData} variant="contained">
                <RefreshIcon className={classes.refreshIcon} />
              </Button>
            </Tooltip>
            {graphLoaded && (
              <Button className={classes.downloadButton} onClick={downloadGraph('pdf')} variant="outlined">
                <FiDownload className={classes.buttonIcon} />
                PDF
              </Button>
            )}
            {graphLoaded && (
              <Button className={classes.downloadButton} onClick={downloadGraph('xlsx')} variant="outlined">
                <FiDownload className={classes.buttonIcon} />
                XLSX
              </Button>
            )}
            {graphLoaded && (
              <Button className={classes.downloadButton} onClick={downloadGraph('csv')} variant="outlined">
                <FiDownload className={classes.buttonIcon} />
                CSV
              </Button>
            )}
          </MuiThemeProvider>
        </Grid>
      </Grid>
      <Grid className={classes.graphContainer} item>
        {!graph
          ? <CircularProgress style={{ color: 'black' }} />
          : (
            <GraphOrError
              className={classes.d3Graph}
              config={graphConfig}
              errorMessage={errorMessage}
              graph={graph}
              graphLoaded={graphLoaded}
              onClick={setRightPanelNode}
              onMouseEnter={() => null}
              rightPanelRef={rightPanelRef}
              subqueries={subqueries}
              {...props}
            />
          )}
      </Grid>
    </>
  )
}
