import React, { useState } from 'react'
import { Controlled as CodeMirror } from 'react-codemirror2'
import Button from '@material-ui/core/Button'
import { green } from '@material-ui/core/colors'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import PlayArrowIcon from '@material-ui/icons/PlayArrowOutlined'
import diff from 'deep-diff'
import urlJoin from 'url-join'

import queryAPI, { QueryAPI as QueryAPIClass } from '../../api/queryAPI'
import BreadCrumbs from '../breadcrumbs'
import { getMustacheVariables } from '../utils'
import QueryDefaultValues from './query-default-values'
import QueryListVersions from './query-versions'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/neo.css'
// eslint-disable-next-line import/extensions
import 'codemirror/mode/cypher/cypher.js'

const useStyles = makeStyles((theme) => ({
  defaultValuesContainer: {
    paddingTop: '1rem',
  },
  endButtonContainer: {
    textAlign: 'end',
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
  },
  gridContainer: {
    marginTop: '3rem',
  },
  inputLabel: {
    color: '#6F8294',
    margin: 0,
  },
  neo4jQueryContainer: {
    '& > div': {
      border: '1px solid #D0D0D0',
      borderRadius: '4px',
      height: '200px',
    },
    '& > div.CodeMirror-focused': {
      borderColor: '#048CC2',
    },
    '& > div:hover': {
      borderColor: '#048CC2',
    },
    paddingTop: '0.5rem',
  },
  notchedOutlineTextInput: {},
  radio: {
    '& svg:nth-child(1)': {
      color: '#6F8294',
    },
    '& svg:nth-child(2)': {
      color: '#048CC2',
    },
  },
  rootTextInput: {
    '& $notchedOutlineTextInput': {
      borderWidth: '1px !important',
    },
    '&:hover $notchedOutlineTextInput': {
      borderColor: '#048CC2',
      borderWidth: '1px !important',
    },
  },
  rowDivider: {
    marginBottom: '1.5rem',
  },
  snackbarIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  snackbarMessage: {
    alignItems: 'center',
    display: 'flex',
  },
  successSnackbar: {
    backgroundColor: green[600],
  },
  textField: {
    backgroundColor: '#fff',
  },
  versionLabel: {
    color: '#6F8294',
    fontSize: '14px',
    textAlign: 'left',
  },
  versionListContainer: {
    paddingLeft: '2rem',
  },
}))

const theme = createTheme({
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
      containedSecondary: {
        color: 'white',
      },
    },
  },
  palette: {
    primary: {
      main: '#048CC2',
    },
    secondary: {
      main: '#71B34C',
    },
  },
})

const getQueryFromProps = ({ location, match }) => (
  (match.params.id && location.state && location.state.query)
    ? location.state.query : QueryAPIClass.newQuery()
)

const canSave = (query) => (
  !query.id
    || !query._first
    || diff(query, query._first, (path, key) => path.length === 0 && ['_first'].includes(key)) !== undefined
)

export default function QueryCreateUpdate({
  breadcrumbs: breadcrumbsProps, history, location, match,
}) {
  const [query, setQuery] = useState(getQueryFromProps({ location, match }))
  const [enableSave, setEnableSave] = useState(true)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarType, setSnackbarType] = useState('error')

  const classes = useStyles()
  const createQueryAllowed = JSON.parse(localStorage.getItem('can_create_query'))
  const consultasPath = '/consultas'
  const breadcrumbs = Array.from(breadcrumbsProps)

  const snackbarTypes = {
    error: {
      class: classes.errorSnackbar,
      icon: <ErrorIcon className={classes.snackbarIcon} />,
      message: 'No se pudo procesar su solicitud.',
    },
    success: {
      class: classes.successSnackbar,
      icon: <CheckCircleIcon className={classes.snackbarIcon} />,
      message: 'Solicitud procesada correctamente.',
    },
  }

  const getQuery = (id) => queryAPI.get(
    id,
    setQuery,
    () => history.push(consultasPath),
  )
  const queryVars = getMustacheVariables(query.neo4jQuery)
  const updateQueryField = (fieldname, value) => {
    const newQuery = {
      ...query,
      [fieldname]: value,
    }
    setQuery(newQuery)
  }
  const updateQueryFieldByEvent = (fieldname) => (event) => {
    updateQueryField(fieldname, event.target.value)
  }
  const createQuery = (data) => queryAPI.create(
    data,
    (createdQuery) => {
      history.push({
        pathname: urlJoin(consultasPath, createdQuery.id.toString(), 'editar'),
        state: {
          query: createdQuery,
        },
      })
      setSnackbarType('success')
      setOpenSnackbar(true)
    },
    () => {
      setSnackbarType('error')
      setOpenSnackbar(true)
    },
  )
  const updateQuery = (data) => queryAPI.update(
    data,
    (res) => {
      getQuery(res.id)
      setSnackbarType('success')
      setOpenSnackbar(true)
    },
    () => {
      setSnackbarType('error')
      setOpenSnackbar(true)
    },
  )

  if (createQueryAllowed === false) {
    history.goBack()
  }
  if (location.pathname.indexOf('agregar') !== -1) {
    breadcrumbs.push({ label: 'Agregar Consulta' })
  } else if (query && query.id) {
    breadcrumbs.push({ label: query.name, route: urlJoin(consultasPath, query.id.toString()) })
    breadcrumbs.push({ label: 'Editar Consulta' })
  }
  if (canSave(query) !== enableSave) {
    setEnableSave(!enableSave)
  }
  if (!query._first) {
    setQuery({
      ...query,
      _first: { ...query },
    })
  }
  if (match.params.id && !query.id) {
    getQuery(match.params.id)
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (query.id) {
      updateQuery(query)
    } else {
      createQuery(query)
    }
  }

  return createQueryAllowed && (
    <Grid
      alignItems="stretch"
      container
      direction="column"
      justify="center"
      spacing={2}
    >
      <Grid item>
        <BreadCrumbs crumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        <MuiThemeProvider theme={theme}>
          <form onSubmit={handleSubmit}>
            <Grid
              alignItems="stretch"
              className={classes.gridContainer}
              container
              direction="row"
              justify="center"
            >
              <Grid item xs={8}>
                <div className={classes.rowDivider}>
                  <Grid
                    alignItems="flex-end"
                    container
                    direction="row"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item lg={8} xs={6}>
                      <p className={classes.inputLabel}>Nombre</p>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        id="query-name"
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutlineTextInput,
                            root: classes.rootTextInput,
                          },
                        }}
                        margin="dense"
                        name="name"
                        onChange={updateQueryFieldByEvent('name')}
                        type="text"
                        value={query.name || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} xs={6}>
                      {query.version && (
                      <p className={classes.versionLabel}>
                        Versión Actual:
                        {' '}
                        {query.version}
                        {' '}
                        -
                        {query.versions[0].modifier.first_name}
                        {' '}
                        {query.versions[0].modifier.last_name}
                      </p>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.rowDivider}>
                  <p className={classes.inputLabel}>Descripción</p>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    id="query-description"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutlineTextInput,
                        root: classes.rootTextInput,
                      },
                    }}
                    margin="dense"
                    multiline
                    name="description"
                    onChange={updateQueryFieldByEvent('description')}
                    rows="5"
                    type="text"
                    value={query.description || ''}
                    variant="outlined"
                  />
                </div>
                <div className={classes.rowDivider}>
                  <p className={classes.inputLabel}>Algoritmo</p>
                  <CodeMirror
                    className={classes.neo4jQueryContainer}
                    onBeforeChange={
                      (_editor, _data, value) => updateQueryField('neo4jQuery', value)
                    }
                    options={{
                      lineNumbers: true,
                      mode: 'cypher',
                      smartIndent: false,
                      theme: 'neo',
                    }}
                    value={query.neo4jQuery}
                  />
                </div>
                {queryVars.length > 0 && (
                <div className={classes.rowDivider}>
                  <p className={classes.inputLabel}>Valores por defecto</p>
                  <QueryDefaultValues
                    className={classes.defaultValuesContainer}
                    defaultValues={query.neo4jQuery_default_values}
                    onUpdate={(values) => updateQueryField('neo4jQuery_default_values', values)}
                    queryVars={queryVars}
                    size={6}
                  />
                </div>
                )}
                <div className={classes.rowDivider}>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    justify="flex-start"
                  >
                    <Grid item md={3} xs={4}>
                      <p className={classes.inputLabel}>Ver resultado como:</p>
                    </Grid>
                    <Grid item md={9} xs={8}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="position"
                          name="type"
                          onChange={updateQueryFieldByEvent('type')}
                          row
                          value={query.type}
                        >
                          <FormControlLabel
                            className={classes.inputLabel}
                            control={<Radio className={classes.radio} color="primary" />}
                            label="Malla"
                            labelPlacement="end"
                            value="G"
                          />
                          <FormControlLabel
                            className={classes.inputLabel}
                            control={<Radio className={classes.radio} color="primary" />}
                            label="Tabla"
                            labelPlacement="end"
                            value="T"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.rowDivider}>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={3}
                  >
                    {enableSave && (
                    <Grid className={classes.endButtonContainer} item xs={4}>
                      <Button
                        color="primary"
                        onClick={enableSave
                          ? () => setQuery(query._first)
                          : history.goBack}
                        variant="outlined"
                      >
                        Deshacer cambios
                      </Button>
                    </Grid>
                    )}
                    {enableSave ? (
                      <Grid className={classes.endButtonContainer} item xs={3}>
                        <Button color="primary" fullWidth type="submit" variant="contained">
                          Guardar
                        </Button>
                      </Grid>
                    ) : (
                      <Grid className={classes.endButtonContainer} item xs={3}>
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() => history.push(
                            urlJoin(consultasPath, query.id.toString()),
                          )}
                          variant="contained"
                        >
                          Visualizar
                          <PlayArrowIcon />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
              <Grid className={classes.versionListContainer} item xs={4}>
                {query.versions && query.versions.length > 1 && <QueryListVersions query={query} />}
              </Grid>
            </Grid>
          </form>
          <Snackbar
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            open={openSnackbar}
          >
            <SnackbarContent
              className={snackbarTypes[snackbarType].class}
              message={(
                <span className={classes.snackbarMessage}>
                  {snackbarTypes[snackbarType].icon}
                  {snackbarTypes[snackbarType].message}
                </span>
              )}
            />
          </Snackbar>
        </MuiThemeProvider>
      </Grid>
    </Grid>
  )
}
