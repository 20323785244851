import React from 'react'
import styled from 'styled-components'

import { rutFormatter } from '../../../utils'
import Property, { DefaultProperties, PropertiesWrapper } from './Property'

const specialFields = [
  'rut',
]

export default function CompanyProperties({ node }) {
  const { rut } = node
  return (
    <Wrapper>
      {rut && (
      <Property
        label="Rut"
        value={rutFormatter(rut.toString()).toUpperCase()}
      />
      )}
      <DefaultProperties node={node} specialFields={specialFields} />
    </Wrapper>
  )
}

const Wrapper = styled(PropertiesWrapper)`
`
