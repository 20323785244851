import Cookies from 'js-cookie'

import API from './API'
import authAPI from './authAPI'

class Neo4jAPI extends API {
  constructor() {
    super()
    this.resource = 'neo4j/query'
  }

  // eslint-disable-next-line no-console
  runQuery(query, refresh, okCallback = console.log, errorCallback = console.log) {
    const csrf = Cookies.get('csrftoken')
    fetch(this.getResourceURL(), {
      body: JSON.stringify({ ...query, refresh }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf,
      },
      method: 'POST',
    })
      .then(authAPI.logoutOnAuthError)
      .then((res) => res.json())
      .then((data) => {
        if (data.result && data.result.errors && data.result.errors.length > 0) {
          console.log('Tenemos error en los resultados')
          errorCallback(this.parseErrors(data.result.errors))
        }
        if (data.is_valid === false && data.errors) {
          console.log('Tenemos error en los resultados 2')
          errorCallback(data.errors)
        } else {
          okCallback(data)
        }
      })
      .catch(errorCallback)
  }

  static parseErrors(errors) {
    const getLastJumpIndex = (message) => message.lastIndexOf('\n')
    return errors.map((error) => `${error.code}: ${error.message.substring(0, getLastJumpIndex(error.message))}`)
  }
}

export default new Neo4jAPI()
