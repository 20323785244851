import React from 'react'
import styled from 'styled-components'

import { capitalizePerWord, nodeProperties } from '../../../utils'

export default function Property({ className, label, value }) {
  return (
    <Wrapper className={className}>
      <Label>
        {`${label}: `}
      </Label>
      {value}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 12px;
  word-wrap: break-word;
`

const Label = styled.span`
  font-weight: 700;
`

export const PropertiesWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;
`

export function DefaultProperties({ node, specialFields }) {
  return (
    <>
      {nodeProperties(node)
        .filter(({ name }) => !specialFields.includes(name.toLowerCase()))
        .map(({ name, value }) => (
          <Property
            key={name}
            label={name}
            value={capitalizePerWord(value)}
          />
        ))}
    </>
  )
}
